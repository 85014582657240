import React from 'react';
import { Table } from 'react-bootstrap';
import { formatAddress, formatTimeAgo } from '../utils/Formatters';
import { hexToString, prettifyTokenAmount } from '@alephium/web3';

const CommentTable = ({ comments }) => {
    return (
        <div className="table-responsive">
            <Table striped bordered hover variant='dark' responsive>
                <thead>
                    <tr>
                        <th>Caller</th>
                        <th>Comment</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {comments.length > 0 ? (
                        comments.map((trade, index) => (
                            <tr key={trade.eventHash}>
                                <td>{formatAddress(trade.field2, undefined)}</td>
                                <td>{hexToString(trade.field4)}</td>
                                <td>{formatTimeAgo(trade.timestamp)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={10} className="text-center">
                                No trades found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default CommentTable;
