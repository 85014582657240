import { Spinner } from "react-bootstrap";


const Loader = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="page-header__content text-center" style={{ margin: "100px" }}>
            <Spinner animation="border" role="status" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Loader;
