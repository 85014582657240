import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectDetailsShort } from '../../components/utils/SaleTypes';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatAddress, formatTimeAgo, parseSocials } from '../../components/utils/Formatters';
import { FungibleTokenMetaData, hexToString, web3 } from '@alephium/web3';
import { useEffect, useState } from 'react';
import { TokenLauncher } from '../../artifacts/ts';
import { getContractConfig } from '../../services/utils';
import { TokenMetaData } from '@/artifacts/ts/types';
import { faDiscord, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useAppContext } from '../../context/AppContext';


export interface ProjectCreationEvent {
    tokenId: string,
    timestamp: number,
    token: FungibleTokenMetaData
}

interface HomePageProjectListProps {
    data: ProjectCreationEvent[],
}

interface ProjectDetail {
    tokenId: string,
    meta: TokenMetaData,
    basic: FungibleTokenMetaData,
    timestamp: number
    mcap: number
}

const HomePageProjectList = ({ data }) => {
    const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/";
    const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);

    const { alphPrice } = useAppContext();

    const [projects, setProjects] = useState<ProjectDetail[]>([] as ProjectDetail[]);

    const loadProject = async function (project: ProjectCreationEvent) {
        const results = await Promise.all([
            tokenLauncher.maps.tokenMetaCollection.get(project.tokenId),
            fetch(`${ANALYTICS_BASE_URL}/trade/mcap/${getContractConfig("OnionRouter").address}?eventIndex=5&tokenId=${project.tokenId}`)
        ]);
        const mcapResp = (await results[1].json()).data;
        return {
            tokenId: project.tokenId,
            meta: results[0],
            basic: project.token,
            timestamp: project.timestamp,
            mcap: mcapResp && mcapResp.length ? mcapResp[0] : 1000
        } as ProjectDetail
    }

    const loadData = async function (listProjects: ProjectCreationEvent[]) {
        const results = await Promise.all(listProjects.map(x => loadProject(x)));
        setProjects(results);
    }


    useEffect(() => {
        if (data) {
            loadData(data)
        }
    }, [data]);

    return (
        <Row>
            {projects &&
                projects.map((project, index) => (
                    <Col sm={12} md={12} lg={6} key={index}>
                        <div className="pink-border mb-4">
                            <Card className="main-token-card">
                                <Card.Body>
                                    <Row>
                                        <Col xl={5} md={6} xs={6}>
                                            <Link to={"/trade?tokenId=" + project.tokenId}>
                                                <img
                                                    src={`${logoBaseUrl}${hexToString(project.meta.logo)}`}
                                                    className="img-fluid"
                                                    style={{ maxHeight: "180px" }}
                                                    alt={`${hexToString(project.basic.name)} logo`}
                                                />
                                            </Link>
                                        </Col>
                                        <Col xl={7} md={6} xs={6}>
                                            <img src='images/icons/alph.png' height={32} width={32} className="chain-icon" />
                                            <h5 className="fw-bold white-text">{hexToString(project.basic.name)}</h5>
                                            <h6 className="yellow-text uppercase">{hexToString(project.basic.symbol)}</h6>
                                            <p className="truncate-2-lines white-text" style={{ height: "70px" }}>
                                                {hexToString(project.meta.description)}
                                            </p>
                                            <div className="d-flex gap-2 mt-2">
                                                {parseSocials(project.meta.socials).twitter && (
                                                    <a
                                                        href={`https://twitter.com/${parseSocials(project.meta.socials).twitter}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="orange-icon"
                                                    >
                                                        <FontAwesomeIcon icon={faTwitter} size="lg" />
                                                    </a>
                                                )}
                                                {parseSocials(project.meta.socials).telegram && (
                                                    <a
                                                        href={`https://t.me/${parseSocials(project.meta.socials).telegram}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="orange-icon"
                                                    >
                                                        <FontAwesomeIcon icon={faTelegram} size="lg" />
                                                    </a>
                                                )}
                                                {parseSocials(project.meta.socials).discord && (
                                                    <a
                                                        href={`https://discord.gg/${parseSocials(project.meta.socials).discord}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="orange-icon"
                                                    >
                                                        <FontAwesomeIcon icon={faDiscord} size="lg" />
                                                    </a>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <span className="small white-text">
                                            Owner: {formatAddress(project.meta.owner, undefined)} <span className="white-muted-text">{formatTimeAgo(project.timestamp)}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="yellow-text fw-bold">Mcap: <span className='white-text'>${(project.mcap * alphPrice).toFixed(2)}</span></span>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>
                ))}
        </Row>

    );
};

export default HomePageProjectList;