"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDebugBytecode = exports.encodeContractField = exports.buildContractByteCode = exports.encodeContractFields = exports.buildScriptByteCode = exports.flattenFields = exports.typeLength = exports.encodeMapKey = exports.decodePrimitive = exports.tryDecodeMapDebugLog = exports.calcFieldSize = exports.encodeMapPrefix = exports.parseMapType = exports.splitFields = exports.encodeScriptField = exports.encodeScriptFieldAsString = exports.encodePrimitiveValues = exports.addressVal = exports.byteVecVal = exports.u256Val = exports.i256Val = exports.boolVal = exports.encodeVmAddress = exports.encodeVmByteVec = exports.encodeVmU256 = exports.encodeVmI256 = exports.encodeVmBool = exports.VmValType = exports.encodeAddress = exports.encodeByteVec = void 0;
const api_1 = require("../api");
const utils_1 = require("../utils");
const codec_1 = require("../codec");
const codec_2 = require("../codec/codec");
const error_1 = require("../error");
function encodeByteVec(hex) {
    if (!(0, utils_1.isHexString)(hex)) {
        throw Error(`Given value ${hex} is not a valid hex string`);
    }
    const bytes = (0, utils_1.hexToBinUnsafe)(hex);
    return codec_1.byteStringCodec.encode(bytes);
}
exports.encodeByteVec = encodeByteVec;
function encodeAddress(address) {
    return utils_1.bs58.decode(address);
}
exports.encodeAddress = encodeAddress;
var VmValType;
(function (VmValType) {
    VmValType[VmValType["Bool"] = 0] = "Bool";
    VmValType[VmValType["I256"] = 1] = "I256";
    VmValType[VmValType["U256"] = 2] = "U256";
    VmValType[VmValType["ByteVec"] = 3] = "ByteVec";
    VmValType[VmValType["Address"] = 4] = "Address";
})(VmValType = exports.VmValType || (exports.VmValType = {}));
function encodeVmBool(bool) {
    return new Uint8Array([VmValType.Bool, ...codec_2.boolCodec.encode(bool)]);
}
exports.encodeVmBool = encodeVmBool;
function encodeVmI256(i256) {
    return new Uint8Array([VmValType.I256, ...codec_1.i256Codec.encode(i256)]);
}
exports.encodeVmI256 = encodeVmI256;
function encodeVmU256(u256) {
    return new Uint8Array([VmValType.U256, ...codec_1.u256Codec.encode(u256)]);
}
exports.encodeVmU256 = encodeVmU256;
function encodeVmByteVec(bytes) {
    return new Uint8Array([VmValType.ByteVec, ...encodeByteVec(bytes)]);
}
exports.encodeVmByteVec = encodeVmByteVec;
function encodeVmAddress(address) {
    return new Uint8Array([VmValType.Address, ...encodeAddress(address)]);
}
exports.encodeVmAddress = encodeVmAddress;
function boolVal(value) {
    return { type: 'Bool', value };
}
exports.boolVal = boolVal;
function i256Val(value) {
    return { type: 'I256', value: BigInt(value) };
}
exports.i256Val = i256Val;
function u256Val(value) {
    return { type: 'U256', value: BigInt(value) };
}
exports.u256Val = u256Val;
function byteVecVal(value) {
    return { type: 'ByteVec', value };
}
exports.byteVecVal = byteVecVal;
function addressVal(value) {
    return { type: 'Address', value };
}
exports.addressVal = addressVal;
function encodePrimitiveValues(values) {
    return encodeFields(values.map(({ type, value }) => ({ name: `${value}`, type, value })));
}
exports.encodePrimitiveValues = encodePrimitiveValues;
function invalidScriptField(tpe, value) {
    return Error(`Invalid script field ${value} for type ${tpe}`);
}
function encodeScriptFieldI256(value) {
    return codec_1.instrCodec.encode((0, codec_1.toI256)(value));
}
function encodeScriptFieldU256(value) {
    return codec_1.instrCodec.encode((0, codec_1.toU256)(value));
}
function encodeScriptFieldAsString(tpe, value) {
    return (0, utils_1.binToHex)(encodeScriptField(tpe, value));
}
exports.encodeScriptFieldAsString = encodeScriptFieldAsString;
function encodeScriptField(tpe, value) {
    switch (tpe) {
        case 'Bool':
            const byte = (0, api_1.toApiBoolean)(value) ? codec_1.ConstTrue.code : codec_1.ConstFalse.code;
            return new Uint8Array([byte]);
        case 'I256':
            const i256 = (0, api_1.toApiNumber256)(value);
            return encodeScriptFieldI256(BigInt(i256));
        case 'U256':
            const u256 = (0, api_1.toApiNumber256)(value);
            return encodeScriptFieldU256(BigInt(u256));
        case 'Address':
            const address = (0, api_1.toApiAddress)(value);
            return new Uint8Array([codec_1.AddressConstCode, ...encodeAddress(address)]);
        default: // ByteVec or Contract
            const hexStr = (0, api_1.toApiByteVec)(value);
            return new Uint8Array([codec_1.BytesConstCode, ...encodeByteVec(hexStr)]);
    }
    throw invalidScriptField(tpe, value);
}
exports.encodeScriptField = encodeScriptField;
function splitFields(fieldsSig) {
    return fieldsSig.types.reduce(([mapFields, fieldsExceptMaps], type, index) => {
        const fieldSig = type.startsWith('Map[') ? mapFields : fieldsExceptMaps;
        fieldSig.names.push(fieldsSig.names[`${index}`]);
        fieldSig.types.push(type);
        fieldSig.isMutable.push(fieldsSig.isMutable[`${index}`]);
        return [mapFields, fieldsExceptMaps];
    }, [
        { names: [], types: [], isMutable: [] },
        { names: [], types: [], isMutable: [] }
    ]);
}
exports.splitFields = splitFields;
function parseMapType(type) {
    if (!type.startsWith('Map[')) {
        throw new Error(`Expected map type, got ${type}`);
    }
    const keyStartIndex = type.indexOf('[');
    const keyEndIndex = type.indexOf(',');
    return [type.slice(keyStartIndex + 1, keyEndIndex), type.slice(keyEndIndex + 1, type.length - 1)];
}
exports.parseMapType = parseMapType;
function encodeMapPrefix(mapIndex) {
    const str = `__map__${mapIndex}__`;
    const bytes = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i += 1) {
        bytes[i] = str.charCodeAt(i);
    }
    return bytes;
}
exports.encodeMapPrefix = encodeMapPrefix;
function fromAscii(str) {
    let result = '';
    for (let i = 0; i < str.length; i += 2) {
        const ascii = parseInt(str.slice(i, i + 2), 16);
        result += String.fromCharCode(ascii);
    }
    return result;
}
function calcFieldSize(type, isMutable, structs) {
    const struct = structs.find((s) => s.name === type);
    if (struct !== undefined) {
        return struct.fieldTypes.reduce((acc, fieldType, index) => {
            const isFieldMutable = isMutable && struct.isMutable[`${index}`];
            const subFieldSize = calcFieldSize(fieldType, isFieldMutable, structs);
            return {
                immFields: acc.immFields + subFieldSize.immFields,
                mutFields: acc.mutFields + subFieldSize.mutFields
            };
        }, { immFields: 0, mutFields: 0 });
    }
    if (type.startsWith('[')) {
        const [baseType, size] = (0, api_1.decodeArrayType)(type);
        const base = calcFieldSize(baseType, isMutable, structs);
        return { immFields: base.immFields * size, mutFields: base.mutFields * size };
    }
    return isMutable ? { immFields: 0, mutFields: 1 } : { immFields: 1, mutFields: 0 };
}
exports.calcFieldSize = calcFieldSize;
function tryDecodeMapDebugLog(message) {
    if (!message.startsWith('insert at map path: ') && !message.startsWith('remove at map path: ')) {
        return undefined;
    }
    const parts = message.split(':');
    if (parts.length !== 2)
        return undefined;
    const pathString = parts[1].slice(1);
    if (!(0, utils_1.isHexString)(pathString))
        return undefined;
    const prefix = '5f5f6d61705f5f'; // __map__
    const remain = pathString.slice(prefix.length);
    const suffix = '5f5f'; // __
    const suffixIndex = remain.indexOf(suffix);
    if (suffixIndex === -1)
        return undefined;
    const encodedMapIndex = remain.slice(0, suffixIndex);
    const mapIndex = parseInt(fromAscii(encodedMapIndex));
    const encodedKey = (0, utils_1.hexToBinUnsafe)(remain.slice(suffixIndex + suffix.length));
    const isInsert = message.startsWith('insert');
    return { path: pathString, mapIndex, encodedKey, isInsert };
}
exports.tryDecodeMapDebugLog = tryDecodeMapDebugLog;
function decodePrimitive(value, type) {
    switch (type) {
        case 'Bool':
            return codec_2.boolCodec.decode(value);
        case 'I256':
            return codec_1.i256Codec.decode(value);
        case 'U256':
            return codec_1.u256Codec.decode(value);
        case 'ByteVec':
            return (0, utils_1.binToHex)(value);
        case 'Address':
            return utils_1.bs58.encode(value);
        default:
            throw Error(`Expected primitive type, got ${type}`);
    }
}
exports.decodePrimitive = decodePrimitive;
function encodeMapKey(value, type) {
    switch (type) {
        case 'Bool':
            const byte = (0, api_1.toApiBoolean)(value) ? 1 : 0;
            return new Uint8Array([byte]);
        case 'I256':
            const i256 = (0, api_1.toApiNumber256)(value);
            return codec_1.i256Codec.encode(BigInt(i256));
        case 'U256':
            const u256 = (0, api_1.toApiNumber256)(value);
            return codec_1.u256Codec.encode(BigInt(u256));
        case 'ByteVec':
            const hexStr = (0, api_1.toApiByteVec)(value);
            return (0, utils_1.hexToBinUnsafe)(hexStr);
        case 'Address':
            const address = (0, api_1.toApiAddress)(value);
            return encodeAddress(address);
        default:
            throw Error(`Expected primitive type, got ${type}`);
    }
}
exports.encodeMapKey = encodeMapKey;
function typeLength(typ, structs) {
    if (api_1.PrimitiveTypes.includes(typ)) {
        return 1;
    }
    if (typ.startsWith('[')) {
        const [baseType, size] = (0, api_1.decodeArrayType)(typ);
        return size * typeLength(baseType, structs);
    }
    const struct = structs.find((s) => s.name === typ);
    if (struct !== undefined) {
        return struct.fieldTypes.reduce((acc, fieldType) => acc + typeLength(fieldType, structs), 0);
    }
    return 1;
}
exports.typeLength = typeLength;
function flattenFields(fields, names, types, isMutable, structs) {
    return names.flatMap((name, index) => {
        if (!(name in fields)) {
            throw new Error(`The value of field ${name} is not provided`);
        }
        return flattenField(isMutable[`${index}`], name, types[`${index}`], fields[`${name}`], structs);
    });
}
exports.flattenFields = flattenFields;
function flattenField(isMutable, name, type, value, structs) {
    if (Array.isArray(value) && type.startsWith('[')) {
        const [baseType, size] = (0, api_1.decodeArrayType)(type);
        if (value.length !== size) {
            throw Error(`Invalid array length, expected ${size}, got ${value.length}`);
        }
        return value.flatMap((item, index) => {
            return flattenField(isMutable, `${name}[${index}]`, baseType, item, structs);
        });
    }
    const struct = structs.find((s) => s.name === type);
    if (struct !== undefined) {
        if (typeof value !== 'object') {
            throw Error(`Expected an object, but got ${typeof value}`);
        }
        return struct.fieldNames.flatMap((fieldName, index) => {
            if (!(fieldName in value)) {
                throw new Error(`The value of field ${fieldName} is not provided`);
            }
            const isFieldMutable = struct.isMutable[`${index}`];
            const fieldType = struct.fieldTypes[`${index}`];
            const fieldValue = value[`${fieldName}`];
            return flattenField(isMutable && isFieldMutable, `${name}.${fieldName}`, fieldType, fieldValue, structs);
        });
    }
    const primitiveType = checkPrimitiveValue(name, type, value);
    return [{ name, type: primitiveType, value, isMutable }];
}
function checkPrimitiveValue(name, ralphType, value) {
    const tsType = typeof value;
    if (ralphType === 'Bool' && tsType === 'boolean') {
        return ralphType;
    }
    if ((ralphType === 'U256' || ralphType === 'I256') &&
        (tsType === 'string' || tsType === 'number' || tsType === 'bigint')) {
        return ralphType;
    }
    if ((ralphType === 'Address' || ralphType === 'ByteVec') && tsType === 'string') {
        return ralphType;
    }
    if (!ralphType.startsWith('[') && tsType === 'string') {
        // contract type
        return 'ByteVec';
    }
    throw Error(`Invalid value ${value} for ${name}, expected a value of type ${ralphType}`);
}
const scriptFieldRegex = /\{([0-9]*)\}/g;
function buildScriptByteCode(bytecodeTemplate, fields, fieldsSig, structs) {
    const allFields = flattenFields(fields, fieldsSig.names, fieldsSig.types, fieldsSig.isMutable, structs);
    return bytecodeTemplate.replace(scriptFieldRegex, (_, fieldIndex) => {
        const field = allFields[`${fieldIndex}`];
        return _encodeField(field.name, () => encodeScriptFieldAsString(field.type, field.value));
    });
}
exports.buildScriptByteCode = buildScriptByteCode;
function _encodeField(fieldName, encodeFunc) {
    try {
        return encodeFunc();
    }
    catch (error) {
        throw new error_1.TraceableError(`Failed to encode the field ${fieldName}`, error);
    }
}
function encodeFields(fields) {
    const prefix = codec_1.i32Codec.encode(fields.length);
    return fields.reduce((acc, field) => {
        const encoded = _encodeField(field.name, () => encodeContractField(field.type, field.value));
        const bytes = new Uint8Array(acc.byteLength + encoded.byteLength);
        bytes.set(acc, 0);
        bytes.set(encoded, acc.byteLength);
        return bytes;
    }, prefix);
}
function encodeContractFields(fields, fieldsSig, structs) {
    const allFields = flattenFields(fields, fieldsSig.names, fieldsSig.types, fieldsSig.isMutable, structs);
    return {
        encodedImmFields: encodeFields(allFields.filter((f) => !f.isMutable)),
        encodedMutFields: encodeFields(allFields.filter((f) => f.isMutable))
    };
}
exports.encodeContractFields = encodeContractFields;
function buildContractByteCode(bytecode, fields, fieldsSig, structs) {
    const { encodedImmFields, encodedMutFields } = encodeContractFields(fields, fieldsSig, structs);
    return bytecode + (0, utils_1.binToHex)(encodedImmFields) + (0, utils_1.binToHex)(encodedMutFields);
}
exports.buildContractByteCode = buildContractByteCode;
function encodeContractField(tpe, value) {
    switch (tpe) {
        case 'Bool':
            return encodeVmBool((0, api_1.toApiBoolean)(value));
        case 'I256':
            return encodeVmI256(BigInt((0, api_1.toApiNumber256)(value)));
        case 'U256':
            return encodeVmU256(BigInt((0, api_1.toApiNumber256)(value)));
        case 'ByteVec':
            return encodeVmByteVec((0, api_1.toApiByteVec)(value));
        case 'Address':
            return encodeVmAddress((0, api_1.toApiAddress)(value));
        default:
            throw Error(`Expected primitive type, got ${tpe}`);
    }
}
exports.encodeContractField = encodeContractField;
function buildDebugBytecode(bytecode, bytecodePatch) {
    if (bytecodePatch === '') {
        return bytecode;
    }
    const pattern = /[=+-][0-9a-f]*/g;
    let result = '';
    let index = 0;
    for (const parts of bytecodePatch.matchAll(pattern)) {
        const part = parts[0];
        const diffType = part[0];
        if (diffType === '=') {
            const length = parseInt(part.substring(1));
            result = result + bytecode.slice(index, index + length);
            index = index + length;
        }
        else if (diffType === '+') {
            result = result + part.substring(1);
        }
        else {
            const length = parseInt(part.substring(1));
            index = index + length;
        }
    }
    return result;
}
exports.buildDebugBytecode = buildDebugBytecode;
// export function buildContractByteCode(
//   compiled: node.TemplateContractByteCode,
//   templateVariables: TemplateVariables
// ): string {
//   const methodsBuilt = compiled.methodsByteCode.map((template) => buildByteCode(template, templateVariables))
//   let count = 0
//   const methodIndexes = methodsBuilt.map((hex) => {
//     count += hex.length / 2
//     return count
//   })
//   return (
//     binToHex(encodeI256(BigInt(compiled.filedLength))) +
//     binToHex(encodeI256(BigInt(methodIndexes.length))) +
//     methodIndexes.map((index) => binToHex(encodeI256(BigInt(index)))).join('') +
//     methodsBuilt.join('')
//   )
// }
