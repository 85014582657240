import { useState } from "react";
import { Navbar, Nav, Container, Dropdown, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AlephiumConnectButton } from "@alephium/web3-react";
import { web3 } from "@alephium/web3";
import { useWallet } from "@alephium/web3-react";
import blockies from 'ethereum-blockies';
import ModalManageProfile from "./modals/profile/ModalManageProfile";
import { formatImageSource } from "./utils/Formatters";

function Header() {
  web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
  const { connectionStatus, nodeProvider, account, signer } = useWallet()
  const profileImage = account ? formatImageSource(account.address, "") : "/images/profile-placeholder.png";

  const [expanded, setExpanded] = useState(false);


  return (
    <Navbar
      id="navbar"
      expand="lg"
      bg="dark"
      variant="dark"
      fixed="top"
      expanded={expanded}
      onToggle={(expanded) => setExpanded(expanded)}
    >
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand as={Link} to="/">
          <img
            src="/images/logo/mini-logo.png"
            alt="logo"
            width={54}
          />
        </Navbar.Brand>

        {/* Hamburger Toggle */}
        <Navbar.Toggle aria-controls="navbar-nav" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            {/* Navigation Links */}
            <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
              Memes
            </Nav.Link>
            <Nav.Link as={Link} to="/vault" onClick={() => setExpanded(false)}>
              Staking
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/leaderboard"
              onClick={() => setExpanded(false)}
            >
              Leaderboard
            </Nav.Link>

            {/* Wallet Connect / Profile Dropdown */}
            <Nav.Item>
              <AlephiumConnectButton.Custom>
                {({ isConnected, disconnect, show, account }) => {
                  return isConnected ? (
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        as="div"
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <Image
                          src={profileImage} // Replace with dynamic profile picture if available
                          roundedCircle
                          width={40}
                          height={40}
                          alt="Profile"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ModalManageProfile />
                        <Dropdown.Item onClick={disconnect}>Disconnect</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <button className="btn btn-primary" onClick={show}>
                      Connect Wallet
                    </button>
                  );
                }}
              </AlephiumConnectButton.Custom>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
