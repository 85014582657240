
import React, { useState, useEffect } from 'react';

import { ProjectDetailsShort } from '../../components/utils/SaleTypes';
import Loader from '../../components/Loader';
import { FungibleTokenMetaData, ONE_ALPH, addressFromContractId, hexToString, stringToHex, waitForTxConfirmation, web3 } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import { getContractConfig, getContractEvents } from '../../services/utils';
import { TokenLauncher } from '../../artifacts/ts/TokenLauncher';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ModalMemeCreate from '../../components/modals/launch/ModalMemeCreate';
import Banner from '../../components/common/Banner';
import HomePageProjectList, { ProjectCreationEvent } from './HomePageProjectList';
import './HomePage.css'
import HomePageSidebar from './HomePageSidebar';

export default function HomePage() {
  const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`
  web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
  const { connectionStatus, nodeProvider, account, signer } = useWallet()

  const [live, setLive] = useState<ProjectCreationEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  const fetchData = async () => {
    const response = await fetch(`${ANALYTICS_BASE_URL}/event/getPaged/${getContractConfig("TokenLauncher").address}?eventIndex=5&page=0&pageSize=1000`);
    const events = await response.json();
    setLive(events.data.map(x => {
      return {
        tokenId: x.field1 + "",
        timestamp: x.timestamp,
        token: {
          name: x.field4 + "",
          symbol: x.field3 + ""
        } as FungibleTokenMetaData
      } as ProjectCreationEvent
    }))
    setLoading(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      
      if (navbar) {
        if (window.scrollY === 0) {
          navbar.style.setProperty('--bs-bg-opacity', '0'); // Transparent
        } else {
          navbar.style.setProperty('--bs-bg-opacity', '1'); // Solid
        }
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
      const navbar = document.getElementById("navbar");
      if (navbar) navbar.style.setProperty('--bs-bg-opacity', '1')
    };
  }, []);

  if (loading) return <Loader></Loader>
  return (
    <>
      <Banner></Banner>
      <Container fluid className="mt-4">
        <Row>
          <Col xl={8} lg={7} md={6} sm={12}>
            <HomePageProjectList data={live} />
          </Col>
          <Col xl={4} lg={5} md={6} sm={12}>
            <HomePageSidebar data={live} />
          </Col>
        </Row>
      </Container>
    </>
  );
}


