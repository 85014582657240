import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { Button, ButtonGroup, Card, CardBody, Container, FormGroup, Table } from 'react-bootstrap';

import { BondingPair, BondingPairInstance, BondingPairTypes, OnionRouter, OnionRouterTypes, OnionRouterInstance, TokenLauncher, TokenLauncherInstance, CommentTracker, ProfileTracker, CommentTrackerInstance, ProfileTrackerInstance } from "../../artifacts/ts";
import { ALPH_TOKEN_ID, DUST_AMOUNT, FungibleTokenMetaData, Number256, ONE_ALPH, ZERO_ADDRESS, addressFromContractId, convertAmountWithDecimals, hexToString, prettifyTokenAmount, stringToHex, web3 } from '@alephium/web3'
import { useWallet } from '@alephium/web3-react';
import { ApadContext } from "../../context/AppContext";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import Loader from "../../components/Loader";
import TradingViewChart from "../../components/partials/TradingViewChart";
import ModalSwapSettings from "../../components/common/ModalSwapSettings";
import { balanceOf, getContractConfig } from "../../services/utils";
import { TokenMetaData } from "@/artifacts/ts/types";
import TradeTable from "../../components/partials/TradeTable";
import CommentTable from "../../components/partials/CommentTable";
import Banner from "../../components/common/Banner";
import TradePageSwap from './TradePageSwap';
import './TradePage.css';
import TradePageStats from './TradePageStats';


const TradePage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`


    const { connectionStatus, nodeProvider, account, signer } = useWallet()

    const onionRouter: OnionRouterInstance = OnionRouter.at(getContractConfig("OnionRouter").address);
    const tokenLauncher: TokenLauncherInstance = TokenLauncher.at(getContractConfig("TokenLauncher").address);
    const commentTracker: CommentTrackerInstance = CommentTracker.at(getContractConfig("CommentTracker").address);
    const profileTracker: ProfileTrackerInstance = ProfileTracker.at(getContractConfig("ProfileTracker").address);


    const [bondingPair, setBondingPair] = useState<BondingPairInstance>();
    const [bondingState, setBondingState] = useState<BondingPairTypes.State>();
    const [tokenId, setTokenId] = useState("");
    const [token, setToken] = useState<FungibleTokenMetaData>();
    const [tokenMeta, setTokenMeta] = useState<TokenMetaData>();
    const [commOrTrade, setCommOrTrade] = useState('comm');
    const [mcap, setMcap] = useState<number>(1000);

    const [loading, setLoading] = useState(true);
    const [trades, setTrades] = useState<any[]>([]);
    const [comments, setComments] = useState<any[]>([]);


    const loadData = async function () {
        web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
        const tokenId = query.get("tokenId") + "";
        setTokenId(tokenId);
        const tokenDetailsResp = await Promise.all([TokenLauncher.at(getContractConfig("TokenLauncher").address).view.loadTokenMeta({ args: { tokenId } }), web3.getCurrentNodeProvider().fetchFungibleTokenMetaData(tokenId)]);
        const tokenMeta = tokenDetailsResp[0];
        setTokenMeta(tokenMeta.returns);
        setToken(tokenDetailsResp[1]);
        if (tokenMeta.returns.bondingCurve) {
            const bPair = BondingPair.at(addressFromContractId(tokenMeta.returns.bondingCurve));
            setBondingPair(bPair);
            setBondingState(await bPair.fetchState());
        }
        const commATradeResp = await Promise.all([
            fetch(`${ANALYTICS_BASE_URL}/comment/get/${commentTracker.address}?pageSize=10&page=0&eventIndex=5&topic=${tokenId}`),
            fetch(`${ANALYTICS_BASE_URL}/trade/get/${onionRouter.address}?pageSize=10&page=0&eventIndex=5&tokenId=${tokenId}`),
            fetch(`${ANALYTICS_BASE_URL}/trade/mcap/${getContractConfig("OnionRouter").address}?eventIndex=5&tokenId=${tokenId}`)
        ]);
        setComments((await commATradeResp[0].json()).data);
        setTrades((await commATradeResp[1].json()).data);
        setMcap((await commATradeResp[2].json()).data[0]);
        setLoading(false);
    }

    useEffect(() => {
        loadData()
        const interval = setInterval(loadData, 5000);
        return () => clearInterval(interval);
    }, []);






    if (loading || !(token && tokenMeta)) return (
        <>
            <Banner></Banner>
            <Container className="mt-4 mb-4">
                <Loader />
            </Container>
        </>
    )

    else
        return (
            <>
                <Container fluid className="mt-5 mb-4 pt-3">
                    <Row className="mt-5">
                        <div className="col-lg-8">
                            <Card>
                                <CardBody>
                                    <TradingViewChart tokenId={tokenId} />
                                </CardBody>
                            </Card>
                            <div className="mt-4">
                                <Card>
                                    <CardBody>
                                        <ButtonGroup aria-label="Toggle Comments/Trades">
                                            <Button variant="secondary" onClick={setCommOrTrade.bind(this, "comm")}>Comments</Button>
                                            <Button variant="secondary" onClick={setCommOrTrade.bind(this, "trade")}>Trades</Button>
                                        </ButtonGroup>
                                        {commOrTrade == "comm" ?
                                            <CommentTable comments={comments}></CommentTable>
                                            :
                                            <TradeTable trades={trades} token={token}></TradeTable>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <TradePageSwap
                                bondingPair={bondingPair}
                                bondingState={bondingState}
                                token={token}
                                tokenMeta={tokenMeta}
                                tokenId={tokenId}
                            />
                            <div className="mt-4">
                                {bondingState && mcap ?
                                    <TradePageStats
                                        bondingPair={bondingPair}
                                        bondingState={bondingState}
                                        token={token}
                                        tokenMeta={tokenMeta}
                                        mcap={mcap}
                                    /> : <></>}
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        );
};
export default TradePage;
