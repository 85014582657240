import React, { useState } from "react";
import { Spinner, Alert } from "react-bootstrap";
import "./PictureHandler.css";

interface PictureHandlerProps {
  label: string;
  minWidth: number;
  minHeight: number;
  onUploadComplete: (fileName: string, fullUrl: string) => void; // Callback after upload
}

const PictureHandler: React.FC<PictureHandlerProps> = ({
  label,
  minWidth,
  minHeight,
  onUploadComplete,
}) => {
  const [preview, setPreview] = useState<string>(""); // Base64 preview
  const [uploading, setUploading] = useState<boolean>(false); // Upload status
  const [errorMessage, setErrorMessage] = useState<string>("");

  const logoBaseUrl = "https://files-api.myonion.fun/"; // Adjust base URL as needed

  const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    const reader = new FileReader();
    const img = new Image();

    reader.onloadend = () => {
      img.src = reader.result as string;
      img.onload = async () => {
        if (img.width < minWidth || img.height < minHeight) {
          setErrorMessage(`The image must be at least ${minWidth}x${minHeight}px.`);
        } else {
          setErrorMessage("");
          setPreview(reader.result as string); // Show preview
          setUploading(true); // Show loader

          try {
            const resp = await fetch("https://files-api.myonion.fun", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ image: reader.result }),
            });

            if (!resp || resp.status !== 200) {
              console.error("Failed to upload image");
              setErrorMessage("Failed to upload image. Please try again.");
            } else {
              const data = await resp.json();
              const fileName = data.fileName;
              const fullUrl = logoBaseUrl + fileName;

              onUploadComplete(fileName, fullUrl); // Trigger callback
            }
          } catch (error) {
            console.error("Upload error:", error);
            setErrorMessage("An error occurred during upload.");
          } finally {
            setUploading(false); // Hide loader
          }
        }
      };
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="upload-container">
      {preview && <img src={preview} alt="Preview" className="preview-image" />}
      {uploading && <Spinner animation="border" className="upload-spinner" />}
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        className="upload-input"
      />
      <div className="upload-label">{label}</div>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
    </div>
  );
};

export default PictureHandler;
