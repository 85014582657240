import React, { useState } from 'react';
import { Col, Container, Form, Row, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ONE_ALPH, stringToHex, waitForTxConfirmation, web3 } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import { getContractConfig } from '../../../services/utils';
import { TokenLauncher } from '../../../artifacts/ts/TokenLauncher';
import GenericImageUpload from '../../common/GenericImageUpload';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PictureHandler from '../../common/pictureHandler/PictureHandler';

const ModalMemeCreate: React.FC = () => {
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,quality=75/";
    const { account, signer } = useWallet();
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL || "https://wallet.mainnet.alephium.org");

    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [logo, setLogo] = useState("");
    const [logoFullUrl, setLogoFullUrl] = useState("");
    const [description, setDescription] = useState("");
    const [royaltyFee, setRoyaltyFee] = useState("");
    const [discord, setDiscord] = useState("");
    const [telegram, setTelegram] = useState("");
    const [twitter, setTwitter] = useState("");

    const showModalClick = () => setShowModal(true);
    const cancelModal = () => setShowModal(false);

    const saveModal = async () => {
        if (signer) {
            const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);
            const socialsCombined = `${twitter ? `tw:${twitter};` : ""}${telegram ? `tg:${telegram};` : ""}${discord ? `ds:${discord};` : ""}`;

            try {
                const tx = await tokenLauncher.transact.createToken({
                    args: {
                        name: stringToHex(name),
                        symbol: stringToHex(symbol),
                        description: stringToHex(description),
                        logo: stringToHex(logo),
                        socials: stringToHex(socialsCombined)
                    },
                    attoAlphAmount: ONE_ALPH,
                    signer,
                });
                await waitForTxConfirmation(tx.txId, 1, 3000);
                cancelModal();
            } catch (e) {
                console.error("Failed to save token:", e);
            }
        }
    };

    const imageUpdate = async (fileName: string, fullUrl: string) => {
        setLogo(fileName);
    };

    return (
        <>
            <Button variant="primary" className="make-coin-button" onClick={showModalClick}>MAKE TOKEN</Button>
            <Modal
                show={showModal}
                onHide={cancelModal}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="modal-dark modal-fullscreen-sm-down"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create a Token</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Container>
                            {/* Profile Picture */}
                            <Row className="mb-3">
                                <Col className="text-center" id="topSection">
                                    <PictureHandler
                                        label="Logo"
                                        minHeight={100}
                                        minWidth={100}
                                        onUploadComplete={imageUpdate}
                                    />
                                </Col>
                            </Row>
                            {/* Name and Symbol */}
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            Token Name &nbsp;
                                            <OverlayTrigger
                                                overlay={<Tooltip>Enter the name of the token.</Tooltip>}
                                                placement="top"
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Token Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            maxLength={32}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="symbol">
                                        <Form.Label>
                                            Token Symbol &nbsp;
                                            <OverlayTrigger
                                                overlay={<Tooltip>Enter the symbol of the token.</Tooltip>}
                                                placement="top"
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Token Symbol"
                                            value={symbol}
                                            onChange={(e) => setSymbol(e.target.value)}
                                            maxLength={10}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Description */}
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter token description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            maxLength={180}
                                            rows={3}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Social Links */}
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="twitter">
                                        <Form.Label>Twitter</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Twitter URL"
                                            value={twitter}
                                            onChange={(e) => setTwitter(e.target.value)}
                                            maxLength={60}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="telegram">
                                        <Form.Label>Telegram</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Telegram URL"
                                            value={telegram}
                                            onChange={(e) => setTelegram(e.target.value)}
                                            maxLength={60}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="discord">
                                        <Form.Label>Discord</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Discord URL"
                                            value={discord}
                                            onChange={(e) => setDiscord(e.target.value)}
                                            maxLength={60}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="royaltyFee">
                                        <Form.Label>Royalty Fee</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Royalty Fee (%)"
                                            value={royaltyFee}
                                            onChange={(e) => setRoyaltyFee(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={saveModal}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalMemeCreate;
