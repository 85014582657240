import { balanceOf, getContractConfig } from "../../services/utils";
import {
    OnionRouter,
    BondingPairTypes,
    OnionRouterInstance,
    TokenLauncherInstance,
    CommentTrackerInstance,
    ProfileTrackerInstance,
    TokenLauncher,
} from "../../artifacts/ts";
import {
    ALPH_TOKEN_ID,
    convertAmountWithDecimals,
    prettifyTokenAmount,
    stringToHex,
    ONE_ALPH,
    ZERO_ADDRESS,
    web3,
    hexToString,
} from "@alephium/web3";
import { useWallet } from "@alephium/web3-react";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row, Spinner } from "react-bootstrap";

interface TradePageSwapProps {
    token: any;
    tokenMeta: any;
    bondingPair: any;
    bondingState: BondingPairTypes.State | undefined;
    tokenId: string;
}

const TradePageSwap: React.FC<TradePageSwapProps> = ({
    token,
    tokenMeta,
    bondingPair,
    bondingState,
    tokenId
}) => {
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
    const { account, signer } = useWallet();

    const [loading, setLoading] = useState(false);
    const [buyOrSell, setBuyOrSell] = useState("buy");
    const [availableAlph, setAvailableAlph] = useState<bigint>(0n);
    const [availableToken, setAvailableToken] = useState<bigint>(0n);
    const [amount, setAmount] = useState("");
    const [output, setOutput] = useState("");
    const [comment, setComment] = useState("");
    const [graduationReady, setGraduationReady] = useState(false);

    const isBuy = buyOrSell === "buy";

    useEffect(() => {
        if (bondingState && bondingState.fields) {
            if (bondingState.fields.tokensSold > bondingState.fields.totalTokens - bondingState.fields.totalTokens / 1000n && bondingState.fields.graduated == false) {
                setGraduationReady(true);
            } else {
                setGraduationReady(false);
            }
        }
    }, [bondingState])

    useEffect(() => {
        const load = async () => {
            const realAmountIn = convertAmountWithDecimals(amount, 18) ?? 0n;
            if (bondingPair && bondingState && realAmountIn && tokenMeta && token) {
                console.log(tokenMeta);
                const estimate = await OnionRouter.at(getContractConfig("OnionRouter").address).view.simulateSwapExactTokenForToken({
                    args: {
                        amountIn: realAmountIn,
                        bondingPair: bondingPair.contractId,
                        slippage: 500n,
                        tokenInId: isBuy ? ALPH_TOKEN_ID : bondingState.fields.tokenId,
                        dexPair: tokenMeta.dexPair ? tokenMeta.dexPair : getContractConfig("DexPair").tokenId
                    }
                });
                setOutput(`${prettifyTokenAmount(estimate.returns[0], 18)} ${isBuy ? hexToString(token.symbol) : "ALPH"}`);
            }
        }
        load();
    }, [amount])

    const loadBalances = async () => {
        if (account && account.address && bondingState) {
            const results = await Promise.all([
                balanceOf(ALPH_TOKEN_ID, account.address),
                balanceOf(tokenId, account.address),
            ]);
            setAvailableAlph(results[0]);
            setAvailableToken(results[1]);
        }
    }

    useEffect(() => {
        loadBalances()
        const interval = setInterval(loadBalances, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleTransaction = async () => {
        if (!bondingPair || !amount || !tokenMeta || !signer || !bondingState) return;
        const amountReal = convertAmountWithDecimals(amount, 18);
        if (!amountReal) return;

        setLoading(true);

        try {
            const args = {
                bondingPair: bondingPair.contractId,
                amountIn: amountReal,
                amountOutMin: 0n,
                caller: account.address,
                deadline: BigInt(Date.now()) + 30000n,
                tokenInId: isBuy ? ALPH_TOKEN_ID : bondingState.fields.tokenId,
                tokenLauncher: getContractConfig("TokenLauncher").tokenId,
                comment: stringToHex(comment),
                commentTracker: getContractConfig("CommentTracker").tokenId,
                profileTracker: getContractConfig("ProfileTracker").tokenId,
                dexPair: tokenMeta.dexPair ? tokenMeta.dexPair : getContractConfig("DexPair").tokenId,
                referrer: ZERO_ADDRESS,
            };

            if (isBuy) {
                args["tokenInId"] = ALPH_TOKEN_ID;
                await OnionRouter.at(getContractConfig("OnionRouter").address).transact.swapExactTokenForToken({
                    args,
                    signer,
                    attoAlphAmount: amountReal + ONE_ALPH,
                });
            } else {
                args["tokenInId"] = bondingState.fields.tokenId;
                await OnionRouter.at(getContractConfig("OnionRouter").address).transact.swapExactTokenForToken({
                    args,
                    signer,
                    attoAlphAmount: ONE_ALPH,
                    tokens: [{ amount: amountReal, id: bondingState.fields.tokenId }],
                });
            }
        } catch (error) {
            console.error("Transaction failed", error);
        } finally {
            setLoading(false);
        }
    };

    const resetFields = () => {
        setAmount("");
        setOutput("");
        setComment("");
    }

    const swapMode = () => {
        if (isBuy) setBuyOrSell("sell");
        else setBuyOrSell("buy");
        resetFields();
    }



    const initBondingCurve = () => {
        if (signer) {
            TokenLauncher.at(getContractConfig("TokenLauncher").address).transact.createBondingCurve({
                args: { tokenId },
                signer,
                attoAlphAmount: ONE_ALPH,
                tokens: [
                    {
                        id: tokenId,
                        amount: 1_000_000_000n * 10n ** 18n
                    }
                ]
            });
        }
    }

    if (!bondingState) return (
        <div className="card">
            <div className="card-body">
                <Row className="mb-3">
                    <Button onClick={initBondingCurve}>Initialize Bonding Curve</Button>
                </Row>
            </div>
        </div>
    )

    const graduate = () => {
        if (signer && bondingState) {
            TokenLauncher.at(getContractConfig("TokenLauncher").address).transact.createDexPair({
                signer,
                args: {
                    bp: bondingState.contractId,
                    tokenId: bondingState.fields.tokenId
                }
            })
        }
    }

    if (graduationReady) return (
        <div className="card">
            <div className="card-body">
                <Row className="mb-3">
                    <Button
                        variant="primary"
                        onClick={() => graduate()}
                    >
                        Graduate To DEX
                    </Button>
                </Row>
            </div>
        </div>
    )


    return (
        <div className="card">
            <div className="card-body">
                <Row className="mb-3">
                    <Col>
                        <ButtonGroup style={{ width: "100%" }}>
                            <Button
                                variant="secondary"
                                onClick={() => swapMode()}
                                active={isBuy}
                            >
                                Buy
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => swapMode()}
                                active={!isBuy}
                            >
                                Sell
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>
                            Available:{" "}
                            <span>
                                {prettifyTokenAmount(isBuy ? availableAlph : availableToken, 18)}{" "}
                                {isBuy ? "ALPH" : hexToString(token.symbol)}
                            </span>
                        </Form.Label>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                pattern="^\d{1,18}(\.\d{1,18})?$"
                                name="amount"
                                value={amount}
                                placeholder={`Enter amount to ${isBuy ? "buy" : "sell"}`}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>You are getting:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="0.00"
                            value={output}
                            readOnly
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Add a comment"
                                name="comment"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            variant={isBuy ? "success" : "danger"}
                            type="button"
                            style={{ width: "100%" }}
                            onClick={handleTransaction}
                        >
                            {isBuy ? "Buy" : "Sell"}{" "}
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TradePageSwap;
