

import React, { useState, } from 'react';
import { Col, Container, Form, Row, Button, Dropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import "../Modal.css"
import "./ModalManageProfile.css"


import { ONE_ALPH, addressFromContractId, hexToString, prettifyTokenAmount, stringToHex, waitForTxConfirmation, web3 } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import { getContractConfig, getContractEvents } from '../../../services/utils';
import { ProfileTracker } from '../../../artifacts/ts';
import { UserProfile } from '../../../artifacts/ts/types';
import PictureHandler from '../../common/pictureHandler/PictureHandler';
import { formatAddress } from '../../utils/Formatters';

const ModalManageProfile: React.FC = (): React.ReactElement | null => {

    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,quality=75/";
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
    const { connectionStatus, nodeProvider, account, signer } = useWallet()
    const profileTracker = ProfileTracker.at(getContractConfig("ProfileTracker").address);

    const [showModal, setShowModal] = useState(false);
    const [profile, setProfile] = useState({
        pfp: "",
        name: "",
        status: "",
        socials: "",
        linkingId: "",
        xp: 0n,
        referrer: "",
    });

    // State for individual social fields
    const [socialLinks, setSocialLinks] = useState({
        telegram: "",
        discord: "",
        twitter: "",
    });

    // Parses the `socials` field into individual fields
    const parseSocials = (socials: string) => {
        const parts = socials.split(";");
        const socialsObject: Record<string, string> = {};
        parts.forEach((part) => {
            const [key, value] = part.split(":");
            socialsObject[key] = value;
        });
        setSocialLinks({
            telegram: socialsObject["tg"] || "",
            discord: socialsObject["ds"] || "",
            twitter: socialsObject["tw"] || "",
        });
    };

    // Combines individual social fields into the `socials` field
    const serializeSocials = () => {
        const { telegram, discord, twitter } = socialLinks;
        return [
            telegram ? `tg:${telegram}` : "",
            discord ? `ds:${discord}` : "",
            twitter ? `tw:${twitter}` : "",
        ]
            .filter(Boolean)
            .join(";");
    };

    const handleInputChange = (field: string, value: string) => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            [field]: value,
        }));
    };

    const handleSocialChange = (field: string, value: string) => {
        setSocialLinks((prevLinks) => ({
            ...prevLinks,
            [field]: value,
        }));
    };

    const imageUpdate = (base64String: string) => {
        handleInputChange("pfp", base64String);
    };


    const loadProfile = async () => {
        if (account) {
            try {
                const profileResp = await profileTracker.view.loadUserProfile({ args: { caller: account.address } });
                console.log(profile);
                setProfile(profileResp.returns)
            } catch (e) {
                console.error(e);
            }
        }
    }

    function showModalClick(e: any) {
        setShowModal(true);
        loadProfile();
    }

    function saveProfile(e: any) {
        e.preventDefault();
        if (signer) {
            // const execute = async () => {
            //     const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);
            //     var tx = await tokenLauncher.transact.createToken({
            //         args: {
            //             name: stringToHex(name),
            //             symbol: stringToHex(symbol),
            //             description: stringToHex(description),
            //             logo: stringToHex(logo),
            //             discord: stringToHex(discord),
            //             telegram: stringToHex(telegram),
            //             twitter: stringToHex(twitter),
            //             royaltyFee: BigInt(royaltyFee)
            //         },
            //         attoAlphAmount: ONE_ALPH,
            //         signer
            //     });
            //     try {
            //         await waitForTxConfirmation(tx.txId, 1, 3000);
            //         const events = await web3.getCurrentNodeProvider().events.getEventsTxIdTxid(tx.txId);
            //         const createEvent = events.events.filter(x => x.contractAddress == getContractConfig("TokenLauncher").address && x.eventIndex == TokenLauncher.eventIndex.CreateToken)[0];
            //         router.push(`/trade?tokenId=${createEvent.fields[0].value}`)
            //     } catch (e) {
            //         console.error(e);
            //     }
            // }
            // execute();
        }
    }

    function cancelModal() {
        setShowModal(false);
    }

    return (
        <>
            <Dropdown.Item onClick={showModalClick}>Manage Profile</Dropdown.Item>
            <Modal
                show={showModal}
                onHide={cancelModal}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="modal-dark modal-fullscreen-sm-down"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Container>
                            {/* Profile Picture */}
                            <Row className="mb-3">
                                <Col className="text-center" id="topSection">
                                    <PictureHandler
                                        label="Profile Picture"
                                        minHeight={100}
                                        minWidth={100}
                                        onUploadComplete={imageUpdate}
                                    />
                                    <p id="xp">XP: {prettifyTokenAmount(profile.xp, 18)}</p>
                                    <p id="referrer">Referrer: {formatAddress(profile.referrer, undefined)}</p>
                                </Col>
                            </Row>

                            {/* Name */}
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="profileName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter your name"
                                            value={profile.name}
                                            maxLength={20}
                                            onChange={(e) =>
                                                handleInputChange("name", e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Status */}
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="profileStatus">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter your status"
                                            value={profile.status}
                                            maxLength={80}
                                            onChange={(e) =>
                                                handleInputChange("status", e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Socials */}
                            {/* Socials */}
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="profileTelegram">
                                        <Form.Label>Telegram Handle</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Telegram Handle"
                                            value={socialLinks.telegram}
                                            maxLength={20}
                                            onChange={(e) =>
                                                handleSocialChange("telegram", e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="profileDiscord">
                                        <Form.Label>Discord Handle</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Discord Handle"
                                            value={socialLinks.discord}
                                            maxLength={20}
                                            onChange={(e) =>
                                                handleSocialChange("discord", e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="profileTwitter">
                                        <Form.Label>Twitter Handle</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Twitter Handle"
                                            value={socialLinks.twitter}
                                            maxLength={20}
                                            onChange={(e) =>
                                                handleSocialChange("twitter", e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={saveProfile}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalManageProfile;