
import { NetworkId, web3, ALPH_TOKEN_ID, NodeProvider, addressFromContractId, addressFromTokenId, FungibleTokenMetaData } from '@alephium/web3'
import { loadDeployments } from '../artifacts/ts/deployments'
import { ContractEvents } from '@alephium/web3/dist/src/api/api-alephium'

export interface ContractConfig {
    network: NetworkId
    groupIndex: number
    address: string
    tokenId: string
}

function getNetwork(): NetworkId {
    const network = (process.env.REACT_APP_NETWORK ?? 'devnet') as NetworkId
    return network
}

export function getContractConfig(contract): ContractConfig {
    try {
        const network = getNetwork()
        const tokenFaucet = loadDeployments(network, process.env.REACT_APP_DEPLOYER).contracts[contract].contractInstance
        const groupIndex = tokenFaucet.groupIndex
        const address = tokenFaucet.address
        const tokenId = tokenFaucet.contractId
        return { network, groupIndex, address, tokenId }
    } catch (e) {
        console.log(e);
        return { network: "mainnet", groupIndex: 0, address: "", tokenId: "" } as ContractConfig;
    }
}


export async function getContractEvents(nodeProvider: NodeProvider, contractAddress: string): Promise<ContractEvents> {
    return await nodeProvider.events.getEventsContractContractaddress(
        contractAddress, { start: 0, limit: 100 }
    )
}


export async function getTokenMeta(nodeProvider: NodeProvider, tokenId: string): Promise<FungibleTokenMetaData> {
    if (!tokenId) return await Promise.resolve({} as FungibleTokenMetaData);
    try {
        return await nodeProvider.fetchFungibleTokenMetaData(tokenId);
    } catch (e) {
        console.log(e);
        return await Promise.resolve({} as FungibleTokenMetaData)
    }
}

export async function balanceOf(tokenId: string, address: string): Promise<bigint> {
    const balances = await web3.getCurrentNodeProvider().addresses.getAddressesAddressBalance(address)
    if (tokenId === ALPH_TOKEN_ID) return BigInt(balances.balance)
    const balance = balances.tokenBalances?.find((t) => t.id === tokenId)
    return balance === undefined ? 0n : BigInt(balance.amount)
}

export async function waitTXConfirmed(nodeProvider, txID) {
    return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            try {
                const txStatus = await nodeProvider.transactions.getTransactionsStatus({ txId: txID });
                if (txStatus.type === 'Confirmed') {
                    clearInterval(interval);
                    resolve(txStatus);
                }
            } catch (error) {
                clearInterval(interval);
                reject(error);
            }
        }, 3000);
    });
}
