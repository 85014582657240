/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as FixedPointMathTestContractJson } from "../dummy/FixedPointMathTest.ral.json";
import { getContractByCodeHash } from "./contracts";
import { TokenMetaData, UserProfile, AllStructs } from "./types";

// Custom types for the contract
export namespace FixedPointMathTestTypes {
  export type State = Omit<ContractState<any>, "fields">;

  export interface CallMethodTable {
    exp: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    pow2: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    expFractional: {
      params: CallContractParams<{ f: bigint }>;
      result: CallContractResult<bigint>;
    };
    ln: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    log2: {
      params: CallContractParams<{ value: bigint }>;
      result: CallContractResult<bigint>;
    };
    scaleToRange: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<[bigint, bigint]>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    exp: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    pow2: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    expFractional: {
      params: SignExecuteContractMethodParams<{ f: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    ln: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    log2: {
      params: SignExecuteContractMethodParams<{ value: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    scaleToRange: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<FixedPointMathTestInstance, {}> {
  encodeFields() {
    return encodeContractFields({}, this.contract.fieldsSig, AllStructs);
  }

  consts = {
    SCALE: BigInt("1000000000000000000"),
    LN2: BigInt("693147180559945309"),
    FixedPointMathErrorCodes: {
      INVALID_INPUT: BigInt("100000"),
      OVERFLOW: BigInt("100001"),
      INVALID_SCALING: BigInt("100003"),
    },
  };

  at(address: string): FixedPointMathTestInstance {
    return new FixedPointMathTestInstance(address);
  }

  tests = {
    exp: async (
      params: Omit<
        TestContractParamsWithoutMaps<never, { x: bigint }>,
        "initialFields"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "exp", params, getContractByCodeHash);
    },
    pow2: async (
      params: Omit<
        TestContractParamsWithoutMaps<never, { x: bigint }>,
        "initialFields"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "pow2", params, getContractByCodeHash);
    },
    expFractional: async (
      params: Omit<
        TestContractParamsWithoutMaps<never, { f: bigint }>,
        "initialFields"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "expFractional", params, getContractByCodeHash);
    },
    ln: async (
      params: Omit<
        TestContractParamsWithoutMaps<never, { x: bigint }>,
        "initialFields"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "ln", params, getContractByCodeHash);
    },
    log2: async (
      params: Omit<
        TestContractParamsWithoutMaps<never, { value: bigint }>,
        "initialFields"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "log2", params, getContractByCodeHash);
    },
    scaleToRange: async (
      params: Omit<
        TestContractParamsWithoutMaps<never, { x: bigint }>,
        "initialFields"
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint]>> => {
      return testMethod(this, "scaleToRange", params, getContractByCodeHash);
    },
  };

  stateForTest(initFields: {}, asset?: Asset, address?: string) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const FixedPointMathTest = new Factory(
  Contract.fromJson(
    FixedPointMathTestContractJson,
    "=9-4=1-2+0c=2-1+2=3-2+66=2+5c=1-1+4dd=138-2+4024=173+0160116021603160416057e070a504f5732202d20783a200f2c20696e7465676572506172743a20122c206672616374696f6e616c506172743a200d2c20696e74526573756c743a200e2c2066726163526573756c743a20122c20636f6d62696e6564526573756c743a2000160=584-1+e=46+1600160216017e0403783a20052c206e3a200c2c20785f7363616c65643a2000=1262",
    "90a7ab989ae0e10b31d2631bd6be00571c37a50f677889523526886b5555d004",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class FixedPointMathTestInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<FixedPointMathTestTypes.State> {
    return fetchContractState(FixedPointMathTest, this);
  }

  view = {
    exp: async (
      params: FixedPointMathTestTypes.CallMethodParams<"exp">
    ): Promise<FixedPointMathTestTypes.CallMethodResult<"exp">> => {
      return callMethod(
        FixedPointMathTest,
        this,
        "exp",
        params,
        getContractByCodeHash
      );
    },
    pow2: async (
      params: FixedPointMathTestTypes.CallMethodParams<"pow2">
    ): Promise<FixedPointMathTestTypes.CallMethodResult<"pow2">> => {
      return callMethod(
        FixedPointMathTest,
        this,
        "pow2",
        params,
        getContractByCodeHash
      );
    },
    expFractional: async (
      params: FixedPointMathTestTypes.CallMethodParams<"expFractional">
    ): Promise<FixedPointMathTestTypes.CallMethodResult<"expFractional">> => {
      return callMethod(
        FixedPointMathTest,
        this,
        "expFractional",
        params,
        getContractByCodeHash
      );
    },
    ln: async (
      params: FixedPointMathTestTypes.CallMethodParams<"ln">
    ): Promise<FixedPointMathTestTypes.CallMethodResult<"ln">> => {
      return callMethod(
        FixedPointMathTest,
        this,
        "ln",
        params,
        getContractByCodeHash
      );
    },
    log2: async (
      params: FixedPointMathTestTypes.CallMethodParams<"log2">
    ): Promise<FixedPointMathTestTypes.CallMethodResult<"log2">> => {
      return callMethod(
        FixedPointMathTest,
        this,
        "log2",
        params,
        getContractByCodeHash
      );
    },
    scaleToRange: async (
      params: FixedPointMathTestTypes.CallMethodParams<"scaleToRange">
    ): Promise<FixedPointMathTestTypes.CallMethodResult<"scaleToRange">> => {
      return callMethod(
        FixedPointMathTest,
        this,
        "scaleToRange",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    exp: async (
      params: FixedPointMathTestTypes.SignExecuteMethodParams<"exp">
    ): Promise<FixedPointMathTestTypes.SignExecuteMethodResult<"exp">> => {
      return signExecuteMethod(FixedPointMathTest, this, "exp", params);
    },
    pow2: async (
      params: FixedPointMathTestTypes.SignExecuteMethodParams<"pow2">
    ): Promise<FixedPointMathTestTypes.SignExecuteMethodResult<"pow2">> => {
      return signExecuteMethod(FixedPointMathTest, this, "pow2", params);
    },
    expFractional: async (
      params: FixedPointMathTestTypes.SignExecuteMethodParams<"expFractional">
    ): Promise<
      FixedPointMathTestTypes.SignExecuteMethodResult<"expFractional">
    > => {
      return signExecuteMethod(
        FixedPointMathTest,
        this,
        "expFractional",
        params
      );
    },
    ln: async (
      params: FixedPointMathTestTypes.SignExecuteMethodParams<"ln">
    ): Promise<FixedPointMathTestTypes.SignExecuteMethodResult<"ln">> => {
      return signExecuteMethod(FixedPointMathTest, this, "ln", params);
    },
    log2: async (
      params: FixedPointMathTestTypes.SignExecuteMethodParams<"log2">
    ): Promise<FixedPointMathTestTypes.SignExecuteMethodResult<"log2">> => {
      return signExecuteMethod(FixedPointMathTest, this, "log2", params);
    },
    scaleToRange: async (
      params: FixedPointMathTestTypes.SignExecuteMethodParams<"scaleToRange">
    ): Promise<
      FixedPointMathTestTypes.SignExecuteMethodResult<"scaleToRange">
    > => {
      return signExecuteMethod(
        FixedPointMathTest,
        this,
        "scaleToRange",
        params
      );
    },
  };

  async multicall<Calls extends FixedPointMathTestTypes.MultiCallParams>(
    calls: Calls
  ): Promise<FixedPointMathTestTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends FixedPointMathTestTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<FixedPointMathTestTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | FixedPointMathTestTypes.MultiCallParams
      | FixedPointMathTestTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      FixedPointMathTest,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
