/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { RunScriptResult, DeployContractExecutionResult } from "@alephium/cli";
import { NetworkId } from "@alephium/web3";
import {
  Token,
  TokenInstance,
  FeeCollector,
  FeeCollectorInstance,
  Meme,
  MemeInstance,
  OnionRouter,
  OnionRouterInstance,
  BondingPair,
  BondingPairInstance,
  DexPair,
  DexPairInstance,
  TokenLauncher,
  TokenLauncherInstance,
  ProfileTracker,
  ProfileTrackerInstance,
  CommentTracker,
  CommentTrackerInstance,
} from ".";
import { default as testnetDeployments } from "../../deployments/.deployments.testnet.json";
import { default as devnetDeployments } from "../../deployments/.deployments.devnet.json";

export type Deployments = {
  deployerAddress: string;
  contracts: {
    Token: DeployContractExecutionResult<TokenInstance>;
    FeeCollector: DeployContractExecutionResult<FeeCollectorInstance>;
    Meme: DeployContractExecutionResult<MemeInstance>;
    OnionRouter: DeployContractExecutionResult<OnionRouterInstance>;
    BondingPair: DeployContractExecutionResult<BondingPairInstance>;
    DexPair: DeployContractExecutionResult<DexPairInstance>;
    TokenLauncher: DeployContractExecutionResult<TokenLauncherInstance>;
    ProfileTracker: DeployContractExecutionResult<ProfileTrackerInstance>;
    CommentTracker: DeployContractExecutionResult<CommentTrackerInstance>;
  };
};

function toDeployments(json: any): Deployments {
  const contracts = {
    Token: {
      ...json.contracts["Token"],
      contractInstance: Token.at(
        json.contracts["Token"].contractInstance.address
      ),
    },
    FeeCollector: {
      ...json.contracts["FeeCollector"],
      contractInstance: FeeCollector.at(
        json.contracts["FeeCollector"].contractInstance.address
      ),
    },
    Meme: {
      ...json.contracts["Meme"],
      contractInstance: Meme.at(
        json.contracts["Meme"].contractInstance.address
      ),
    },
    OnionRouter: {
      ...json.contracts["OnionRouter"],
      contractInstance: OnionRouter.at(
        json.contracts["OnionRouter"].contractInstance.address
      ),
    },
    BondingPair: {
      ...json.contracts["BondingPair"],
      contractInstance: BondingPair.at(
        json.contracts["BondingPair"].contractInstance.address
      ),
    },
    DexPair: {
      ...json.contracts["DexPair"],
      contractInstance: DexPair.at(
        json.contracts["DexPair"].contractInstance.address
      ),
    },
    TokenLauncher: {
      ...json.contracts["TokenLauncher"],
      contractInstance: TokenLauncher.at(
        json.contracts["TokenLauncher"].contractInstance.address
      ),
    },
    ProfileTracker: {
      ...json.contracts["ProfileTracker"],
      contractInstance: ProfileTracker.at(
        json.contracts["ProfileTracker"].contractInstance.address
      ),
    },
    CommentTracker: {
      ...json.contracts["CommentTracker"],
      contractInstance: CommentTracker.at(
        json.contracts["CommentTracker"].contractInstance.address
      ),
    },
  };
  return {
    ...json,
    contracts: contracts as Deployments["contracts"],
  };
}

export function loadDeployments(
  networkId: NetworkId,
  deployerAddress?: string
): Deployments {
  const deployments =
    networkId === "testnet"
      ? testnetDeployments
      : networkId === "devnet"
      ? devnetDeployments
      : undefined;
  if (deployments === undefined) {
    throw Error("The contract has not been deployed to the " + networkId);
  }
  const allDeployments: any[] = Array.isArray(deployments)
    ? deployments
    : [deployments];
  if (deployerAddress === undefined) {
    if (allDeployments.length > 1) {
      throw Error(
        "The contract has been deployed multiple times on " +
          networkId +
          ", please specify the deployer address"
      );
    } else {
      return toDeployments(allDeployments[0]);
    }
  }
  const result = allDeployments.find(
    (d) => d.deployerAddress === deployerAddress
  );
  if (result === undefined) {
    throw Error("The contract deployment result does not exist");
  }
  return toDeployments(result);
}
