/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as CommentTrackerContractJson } from "../value_add/CommentTracker.ral.json";
import { getContractByCodeHash } from "./contracts";
import { TokenMetaData, UserProfile, AllStructs } from "./types";

// Custom types for the contract
export namespace CommentTrackerTypes {
  export type Fields = {
    upgradeDelay: bigint;
    router: Address;
    commentId: bigint;
    owner: Address;
    newOwner: Address;
    upgradeCommenced: bigint;
    newCode: HexString;
  };

  export type State = ContractState<Fields>;

  export type ChangeOwnerCommenceEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type ChangeOwnerApplyEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type MigrateCommenceEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateWithFieldsApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
    changeImmFieldsEncoded: HexString;
    changeMutFieldsEncoded: HexString;
  }>;
  export type CommentEvent = ContractEvent<{
    topic: HexString;
    caller: Address;
    commentId: bigint;
    comment: HexString;
  }>;
  export type UpdateRouterAddressEvent = ContractEvent<{
    caller: Address;
    oldRouter: Address;
    newRouter: Address;
  }>;

  export interface CallMethodTable {
    changeOwner: {
      params: CallContractParams<{ changeOwner: Address }>;
      result: CallContractResult<null>;
    };
    migrate: {
      params: CallContractParams<{ changeCode: HexString }>;
      result: CallContractResult<null>;
    };
    changeOwnerApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateWithFieldsApply: {
      params: CallContractParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: CallContractResult<null>;
    };
    resetUpgrade: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    getUpgradeDelay: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getNewOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getUpgradeCommenced: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getNewCode: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    resetFields: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertOnlyOwner: {
      params: CallContractParams<{ caller: Address }>;
      result: CallContractResult<null>;
    };
    assertUpgradeNotPending: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    postCommentFromRouter: {
      params: CallContractParams<{
        caller: Address;
        topic: HexString;
        comment: HexString;
      }>;
      result: CallContractResult<null>;
    };
    updateRouterAddress: {
      params: CallContractParams<{ newRouter: Address }>;
      result: CallContractResult<null>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    changeOwner: {
      params: SignExecuteContractMethodParams<{ changeOwner: Address }>;
      result: SignExecuteScriptTxResult;
    };
    migrate: {
      params: SignExecuteContractMethodParams<{ changeCode: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    changeOwnerApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateWithFieldsApply: {
      params: SignExecuteContractMethodParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    resetUpgrade: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeDelay: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeCommenced: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewCode: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    resetFields: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertOnlyOwner: {
      params: SignExecuteContractMethodParams<{ caller: Address }>;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeNotPending: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    postCommentFromRouter: {
      params: SignExecuteContractMethodParams<{
        caller: Address;
        topic: HexString;
        comment: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    updateRouterAddress: {
      params: SignExecuteContractMethodParams<{ newRouter: Address }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<
  CommentTrackerInstance,
  CommentTrackerTypes.Fields
> {
  encodeFields(fields: CommentTrackerTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = {
    ChangeOwnerCommence: 0,
    ChangeOwnerApply: 1,
    MigrateCommence: 2,
    MigrateApply: 3,
    MigrateWithFieldsApply: 4,
    Comment: 5,
    UpdateRouterAddress: 6,
  };
  consts = {
    UpgradeErrorCodes: {
      Forbidden: BigInt("13000"),
      UpgradePending: BigInt("13001"),
      UpgradeNotPending: BigInt("13002"),
      UpgradeDelayNotExpired: BigInt("13003"),
      MigrateNotPending: BigInt("13004"),
      MigrateWithFieldsNotPending: BigInt("13005"),
      ChangeOwnerNotPending: BigInt("13006"),
    },
    ErrorCodes: { Unauthorized: BigInt("1") },
  };

  at(address: string): CommentTrackerInstance {
    return new CommentTrackerInstance(address);
  }

  tests = {
    changeOwner: async (
      params: TestContractParamsWithoutMaps<
        CommentTrackerTypes.Fields,
        { changeOwner: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "changeOwner", params, getContractByCodeHash);
    },
    migrate: async (
      params: TestContractParamsWithoutMaps<
        CommentTrackerTypes.Fields,
        { changeCode: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrate", params, getContractByCodeHash);
    },
    changeOwnerApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "changeOwnerApply",
        params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrateApply", params, getContractByCodeHash);
    },
    migrateWithFieldsApply: async (
      params: TestContractParamsWithoutMaps<
        CommentTrackerTypes.Fields,
        { newImmFieldsEncoded: HexString; newMutFieldsEncoded: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetUpgrade", params, getContractByCodeHash);
    },
    getUpgradeDelay: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getUpgradeDelay", params, getContractByCodeHash);
    },
    getOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getOwner", params, getContractByCodeHash);
    },
    getNewOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getNewOwner", params, getContractByCodeHash);
    },
    getUpgradeCommenced: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getUpgradeCommenced",
        params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getNewCode", params, getContractByCodeHash);
    },
    resetFields: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetFields", params, getContractByCodeHash);
    },
    assertOnlyOwner: async (
      params: TestContractParamsWithoutMaps<
        CommentTrackerTypes.Fields,
        { caller: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "assertOnlyOwner", params, getContractByCodeHash);
    },
    assertUpgradeNotPending: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeNotPending",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params: Omit<
        TestContractParamsWithoutMaps<CommentTrackerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeDelayElapsed",
        params,
        getContractByCodeHash
      );
    },
    postCommentFromRouter: async (
      params: TestContractParamsWithoutMaps<
        CommentTrackerTypes.Fields,
        { caller: Address; topic: HexString; comment: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "postCommentFromRouter",
        params,
        getContractByCodeHash
      );
    },
    updateRouterAddress: async (
      params: TestContractParamsWithoutMaps<
        CommentTrackerTypes.Fields,
        { newRouter: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "updateRouterAddress",
        params,
        getContractByCodeHash
      );
    },
  };

  stateForTest(
    initFields: CommentTrackerTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const CommentTracker = new Factory(
  Contract.fromJson(
    CommentTrackerContractJson,
    "",
    "2fa779b63d882c15c81deb2dbdba8950da08a5cabeeb12a7536580ec0ff18a6e",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class CommentTrackerInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<CommentTrackerTypes.State> {
    return fetchContractState(CommentTracker, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeChangeOwnerCommenceEvent(
    options: EventSubscribeOptions<CommentTrackerTypes.ChangeOwnerCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      CommentTracker.contract,
      this,
      options,
      "ChangeOwnerCommence",
      fromCount
    );
  }

  subscribeChangeOwnerApplyEvent(
    options: EventSubscribeOptions<CommentTrackerTypes.ChangeOwnerApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      CommentTracker.contract,
      this,
      options,
      "ChangeOwnerApply",
      fromCount
    );
  }

  subscribeMigrateCommenceEvent(
    options: EventSubscribeOptions<CommentTrackerTypes.MigrateCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      CommentTracker.contract,
      this,
      options,
      "MigrateCommence",
      fromCount
    );
  }

  subscribeMigrateApplyEvent(
    options: EventSubscribeOptions<CommentTrackerTypes.MigrateApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      CommentTracker.contract,
      this,
      options,
      "MigrateApply",
      fromCount
    );
  }

  subscribeMigrateWithFieldsApplyEvent(
    options: EventSubscribeOptions<CommentTrackerTypes.MigrateWithFieldsApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      CommentTracker.contract,
      this,
      options,
      "MigrateWithFieldsApply",
      fromCount
    );
  }

  subscribeCommentEvent(
    options: EventSubscribeOptions<CommentTrackerTypes.CommentEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      CommentTracker.contract,
      this,
      options,
      "Comment",
      fromCount
    );
  }

  subscribeUpdateRouterAddressEvent(
    options: EventSubscribeOptions<CommentTrackerTypes.UpdateRouterAddressEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      CommentTracker.contract,
      this,
      options,
      "UpdateRouterAddress",
      fromCount
    );
  }

  subscribeAllEvents(
    options: EventSubscribeOptions<
      | CommentTrackerTypes.ChangeOwnerCommenceEvent
      | CommentTrackerTypes.ChangeOwnerApplyEvent
      | CommentTrackerTypes.MigrateCommenceEvent
      | CommentTrackerTypes.MigrateApplyEvent
      | CommentTrackerTypes.MigrateWithFieldsApplyEvent
      | CommentTrackerTypes.CommentEvent
      | CommentTrackerTypes.UpdateRouterAddressEvent
    >,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvents(
      CommentTracker.contract,
      this,
      options,
      fromCount
    );
  }

  view = {
    changeOwner: async (
      params: CommentTrackerTypes.CallMethodParams<"changeOwner">
    ): Promise<CommentTrackerTypes.CallMethodResult<"changeOwner">> => {
      return callMethod(
        CommentTracker,
        this,
        "changeOwner",
        params,
        getContractByCodeHash
      );
    },
    migrate: async (
      params: CommentTrackerTypes.CallMethodParams<"migrate">
    ): Promise<CommentTrackerTypes.CallMethodResult<"migrate">> => {
      return callMethod(
        CommentTracker,
        this,
        "migrate",
        params,
        getContractByCodeHash
      );
    },
    changeOwnerApply: async (
      params?: CommentTrackerTypes.CallMethodParams<"changeOwnerApply">
    ): Promise<CommentTrackerTypes.CallMethodResult<"changeOwnerApply">> => {
      return callMethod(
        CommentTracker,
        this,
        "changeOwnerApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params?: CommentTrackerTypes.CallMethodParams<"migrateApply">
    ): Promise<CommentTrackerTypes.CallMethodResult<"migrateApply">> => {
      return callMethod(
        CommentTracker,
        this,
        "migrateApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateWithFieldsApply: async (
      params: CommentTrackerTypes.CallMethodParams<"migrateWithFieldsApply">
    ): Promise<
      CommentTrackerTypes.CallMethodResult<"migrateWithFieldsApply">
    > => {
      return callMethod(
        CommentTracker,
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params?: CommentTrackerTypes.CallMethodParams<"resetUpgrade">
    ): Promise<CommentTrackerTypes.CallMethodResult<"resetUpgrade">> => {
      return callMethod(
        CommentTracker,
        this,
        "resetUpgrade",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeDelay: async (
      params?: CommentTrackerTypes.CallMethodParams<"getUpgradeDelay">
    ): Promise<CommentTrackerTypes.CallMethodResult<"getUpgradeDelay">> => {
      return callMethod(
        CommentTracker,
        this,
        "getUpgradeDelay",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getOwner: async (
      params?: CommentTrackerTypes.CallMethodParams<"getOwner">
    ): Promise<CommentTrackerTypes.CallMethodResult<"getOwner">> => {
      return callMethod(
        CommentTracker,
        this,
        "getOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewOwner: async (
      params?: CommentTrackerTypes.CallMethodParams<"getNewOwner">
    ): Promise<CommentTrackerTypes.CallMethodResult<"getNewOwner">> => {
      return callMethod(
        CommentTracker,
        this,
        "getNewOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeCommenced: async (
      params?: CommentTrackerTypes.CallMethodParams<"getUpgradeCommenced">
    ): Promise<CommentTrackerTypes.CallMethodResult<"getUpgradeCommenced">> => {
      return callMethod(
        CommentTracker,
        this,
        "getUpgradeCommenced",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params?: CommentTrackerTypes.CallMethodParams<"getNewCode">
    ): Promise<CommentTrackerTypes.CallMethodResult<"getNewCode">> => {
      return callMethod(
        CommentTracker,
        this,
        "getNewCode",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    resetFields: async (
      params?: CommentTrackerTypes.CallMethodParams<"resetFields">
    ): Promise<CommentTrackerTypes.CallMethodResult<"resetFields">> => {
      return callMethod(
        CommentTracker,
        this,
        "resetFields",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertOnlyOwner: async (
      params: CommentTrackerTypes.CallMethodParams<"assertOnlyOwner">
    ): Promise<CommentTrackerTypes.CallMethodResult<"assertOnlyOwner">> => {
      return callMethod(
        CommentTracker,
        this,
        "assertOnlyOwner",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeNotPending: async (
      params?: CommentTrackerTypes.CallMethodParams<"assertUpgradeNotPending">
    ): Promise<
      CommentTrackerTypes.CallMethodResult<"assertUpgradeNotPending">
    > => {
      return callMethod(
        CommentTracker,
        this,
        "assertUpgradeNotPending",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params?: CommentTrackerTypes.CallMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      CommentTrackerTypes.CallMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return callMethod(
        CommentTracker,
        this,
        "assertUpgradeDelayElapsed",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    postCommentFromRouter: async (
      params: CommentTrackerTypes.CallMethodParams<"postCommentFromRouter">
    ): Promise<
      CommentTrackerTypes.CallMethodResult<"postCommentFromRouter">
    > => {
      return callMethod(
        CommentTracker,
        this,
        "postCommentFromRouter",
        params,
        getContractByCodeHash
      );
    },
    updateRouterAddress: async (
      params: CommentTrackerTypes.CallMethodParams<"updateRouterAddress">
    ): Promise<CommentTrackerTypes.CallMethodResult<"updateRouterAddress">> => {
      return callMethod(
        CommentTracker,
        this,
        "updateRouterAddress",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    changeOwner: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"changeOwner">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"changeOwner">> => {
      return signExecuteMethod(CommentTracker, this, "changeOwner", params);
    },
    migrate: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"migrate">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"migrate">> => {
      return signExecuteMethod(CommentTracker, this, "migrate", params);
    },
    changeOwnerApply: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"changeOwnerApply">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"changeOwnerApply">
    > => {
      return signExecuteMethod(
        CommentTracker,
        this,
        "changeOwnerApply",
        params
      );
    },
    migrateApply: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"migrateApply">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"migrateApply">> => {
      return signExecuteMethod(CommentTracker, this, "migrateApply", params);
    },
    migrateWithFieldsApply: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"migrateWithFieldsApply">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"migrateWithFieldsApply">
    > => {
      return signExecuteMethod(
        CommentTracker,
        this,
        "migrateWithFieldsApply",
        params
      );
    },
    resetUpgrade: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"resetUpgrade">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"resetUpgrade">> => {
      return signExecuteMethod(CommentTracker, this, "resetUpgrade", params);
    },
    getUpgradeDelay: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"getUpgradeDelay">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"getUpgradeDelay">
    > => {
      return signExecuteMethod(CommentTracker, this, "getUpgradeDelay", params);
    },
    getOwner: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"getOwner">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"getOwner">> => {
      return signExecuteMethod(CommentTracker, this, "getOwner", params);
    },
    getNewOwner: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"getNewOwner">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"getNewOwner">> => {
      return signExecuteMethod(CommentTracker, this, "getNewOwner", params);
    },
    getUpgradeCommenced: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"getUpgradeCommenced">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"getUpgradeCommenced">
    > => {
      return signExecuteMethod(
        CommentTracker,
        this,
        "getUpgradeCommenced",
        params
      );
    },
    getNewCode: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"getNewCode">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"getNewCode">> => {
      return signExecuteMethod(CommentTracker, this, "getNewCode", params);
    },
    resetFields: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"resetFields">
    ): Promise<CommentTrackerTypes.SignExecuteMethodResult<"resetFields">> => {
      return signExecuteMethod(CommentTracker, this, "resetFields", params);
    },
    assertOnlyOwner: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"assertOnlyOwner">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"assertOnlyOwner">
    > => {
      return signExecuteMethod(CommentTracker, this, "assertOnlyOwner", params);
    },
    assertUpgradeNotPending: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"assertUpgradeNotPending">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"assertUpgradeNotPending">
    > => {
      return signExecuteMethod(
        CommentTracker,
        this,
        "assertUpgradeNotPending",
        params
      );
    },
    assertUpgradeDelayElapsed: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return signExecuteMethod(
        CommentTracker,
        this,
        "assertUpgradeDelayElapsed",
        params
      );
    },
    postCommentFromRouter: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"postCommentFromRouter">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"postCommentFromRouter">
    > => {
      return signExecuteMethod(
        CommentTracker,
        this,
        "postCommentFromRouter",
        params
      );
    },
    updateRouterAddress: async (
      params: CommentTrackerTypes.SignExecuteMethodParams<"updateRouterAddress">
    ): Promise<
      CommentTrackerTypes.SignExecuteMethodResult<"updateRouterAddress">
    > => {
      return signExecuteMethod(
        CommentTracker,
        this,
        "updateRouterAddress",
        params
      );
    },
  };

  async multicall<Calls extends CommentTrackerTypes.MultiCallParams>(
    calls: Calls
  ): Promise<CommentTrackerTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends CommentTrackerTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<CommentTrackerTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | CommentTrackerTypes.MultiCallParams
      | CommentTrackerTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      CommentTracker,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
