import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectDetailsShort } from '../../components/utils/SaleTypes';
import { Badge, Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatAddress, formatTimeAgo, parseSocials } from '../../components/utils/Formatters';
import { FungibleTokenMetaData, hexToString, web3 } from '@alephium/web3';
import { useEffect, useState } from 'react';
import { TokenLauncher } from '../../artifacts/ts';
import { getContractConfig } from '../../services/utils';
import { TokenMetaData } from '@/artifacts/ts/types';
import { faDiscord, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useAppContext } from '../../context/AppContext';
import './HomePageSidebar.css';
import ModalMemeCreate from '../../components/modals/launch/ModalMemeCreate';


export interface ProjectCreationEvent {
    tokenId: string,
    timestamp: number,
    token: FungibleTokenMetaData
}

interface HomePageProjectListProps {
    data: ProjectCreationEvent[],
}

interface ProjectDetail {
    tokenId: string,
    meta: TokenMetaData,
    basic: FungibleTokenMetaData,
    timestamp: number
    mcap: number
}

const HomePageSidebar = ({ data }) => {
    const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/";
    const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);

    const { alphPrice } = useAppContext();

    const [latest, setLatest] = useState<ProjectDetail[]>([]);
    const [top, setTop] = useState<ProjectDetail[]>([]);
    const [loading, setLoading] = useState(true);


    const fetchLatest = async () => {
        try {
            const response = await fetch(
                `${ANALYTICS_BASE_URL}/event/getPaged/${getContractConfig("TokenLauncher").address}?eventIndex=5&page=0&pageSize=3`
            );
            const events = (await response.json()).data;

            // Fetch metadata for all events in parallel
            const metadataPromises = events.map((event: any) =>
                TokenLauncher.at(getContractConfig("TokenLauncher").address).view.loadTokenMeta({
                    args: { tokenId: event.field1 },
                })
            );

            const metadataResults = await Promise.all(metadataPromises);

            // Map events to ProjectDetail
            const dataParsed = events.map((event: any, index: number) => ({
                tokenId: event.field1 + "",
                timestamp: event.timestamp,
                meta: metadataResults[index].returns,
                mcap: 0,
                basic: {
                    name: event.field4 + "",
                    symbol: event.field3 + "",
                } as FungibleTokenMetaData,
            }));

            setLatest(dataParsed);
        } catch (error) {
            console.error("Error fetching latest data:", error);
        }
    };

    const fetchTop = async () => {
        try {
            const response = await fetch(
                `${ANALYTICS_BASE_URL}/event/getPaged/${getContractConfig("TokenLauncher").address}?eventIndex=5&page=0&pageSize=5`
            );
            const events = (await response.json()).data;

            // Fetch metadata for all events in parallel
            const metadataPromises = events.map((event: any) =>
                TokenLauncher.at(getContractConfig("TokenLauncher").address).view.loadTokenMeta({
                    args: { tokenId: event.field1 },
                })
            );

            const metadataResults = await Promise.all(metadataPromises);

            // Map events to ProjectDetail
            const dataParsed = events.map((event: any, index: number) => ({
                tokenId: event.field1 + "",
                timestamp: event.timestamp,
                meta: metadataResults[index].returns,
                mcap: 0,
                basic: {
                    name: event.field4 + "",
                    symbol: event.field3 + "",
                } as FungibleTokenMetaData,
            }));

            setTop(dataParsed);
        } catch (error) {
            console.error("Error fetching top data:", error);
        }
    };

    useEffect(() => {
        fetchLatest();
        fetchTop();
        
        const interval = setInterval(() => {
            fetchLatest();
            fetchTop();
        }, 5000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div>
            {/* Start a New Coin */}
            <Card className="start-coin-card text-center mb-3 pink-border">
                <Card.Img variant="top" src="images/bg/newcoin.png" alt="Start a New Coin" className="start-coin-image" />
                <Card.ImgOverlay>
                    <ModalMemeCreate></ModalMemeCreate>
                </Card.ImgOverlay>
            </Card>

            {/* Top Tokens */}
            <div className=" mb-3 gold-to-red-border">
                <Card className="top-tokens-card">
                    <Card.Header className="top-tokens-header">🏆 TOP TOKENS</Card.Header>
                    <ListGroup variant="flush">
                        {top && top.map((project, index) => (
                            <ListGroup.Item className="top-token-item gold-to-red-border">
                                <div className="d-flex align-items-center inner">
                                    <img src={`${logoBaseUrl}${hexToString(project.meta.logo)}`} alt="Token" className="token-image me-2" />
                                    <div>
                                        <div className="token-name">{hexToString(project.basic.name)}</div>
                                        <div className="token-symbol">{hexToString(project.basic.symbol)}</div>
                                        <div className="token-mcap">MCap: $10,000</div>
                                    </div>
                                    <img src={`images/icons/${index + 1}.png`} height={32} width={40} className="ms-auto me-2" />
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
            </div>

            {/* Latest Coins Listed */}
            <div className='mb-3 pink-border'>
                <Card className="latest-coins-card">
                    <Card.Header className="latest-coins-header">🔥 LATEST TOKENS LISTED</Card.Header>
                    <ListGroup variant="flush">
                        {latest && latest.map((project, index) => (
                            <ListGroup.Item className="latest-coin-item white-border">
                                <div className="d-flex align-items-center inner">
                                    <img src={`${logoBaseUrl}${hexToString(project.meta.logo)}`} alt="Coin" className="token-image me-2" />
                                    <div>
                                        <div className="coin-name">{hexToString(project.basic.name)}</div>
                                        <div className="coin-category">{hexToString(project.basic.symbol)}</div>
                                    </div>
                                    <img src='images/icons/alph.png' height={32} width={32} className="ms-auto me-2" />
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
            </div>
        </div>
    );
};

export default HomePageSidebar;