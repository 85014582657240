
import React, { useState, useEffect } from 'react';

import { prettifyTokenAmount } from '@alephium/web3'

import { Card, CardBody, CardTitle, Col, Container, Row, Table } from 'react-bootstrap';
import { getContractConfig } from '../../services/utils';
import { formatAddress, formatImageSource, formatTimeAgo } from '../../components/utils/Formatters';
import './LeaderboardPage.css'


const LeaderboardPage = () => {
    const API_URL = process.env.REACT_APP_ANALYTICS_URL + `/api/profile/get/${getContractConfig("ProfileTracker").address}?eventIndex=5&pageSize=1000&page=0`;

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [stakers, setStakers] = useState<any[]>([]);

    useEffect(() => {
        new Promise(async () => {
            try {
                if (loading == false) {
                    setLoading(true);
                    const response = await fetch(`${API_URL}`);
                    if (!response.ok) {
                        throw new Error(`Error: ${response.statusText}`);
                    }
                    const resp = await response.json();
                    setStakers(resp.data);
                    setLoading(false);
                }
            } catch (err) {
                setError(err as Error);
                setLoading(false);
            }
        })
    }, []);

    return (
        <>
            <Container fluid className="mt-5 mb-5">
                <Row>
                    <Col>
                        <img src="images/bg/leaderboard-banner2.png" width={"100%"} className='mt-5 mb-3'></img>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>a</Col>
                    <Col lg={8}>
                        <div className="pink-border mt-3">
                            <Card className="mainTable">
                                <CardBody>
                                    {stakers && stakers.map((val, index) => (
                                        <div key={index} className="d-flex align-items-center py-3 px-3 rounded">
                                            <div className="me-3 d-flex justify-content-center align-items-center" style={{ width: '40px', height: '40px', backgroundColor: '#3C2757', borderRadius: '50%' }}>
                                                <span className="text-white fw-bold">{index + 1}</span>
                                            </div>
                                            <div className="flex-grow-1 d-flex flex-column">
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={formatImageSource(val.caller, val.pfp)}
                                                        alt="avatar"
                                                        className="rounded-circle me-3"
                                                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                                    />
                                                    <span className="white-text fw-bold">{formatAddress(val.caller, undefined)}</span>
                                                    <a href="#" className="ms-2 text-decoration-none text-info" style={{ fontSize: '0.8rem' }} target="_blank" rel="noopener noreferrer">
                                                        ↗
                                                    </a>
                                                    <div className="ms-3 mt-1 white-muted-text" style={{ fontSize: '0.9rem' }}>
                                                        <span className="me-4">Joined: {formatTimeAgo(val.timestamp)}</span>
                                                        <span>Last trade: {formatTimeAgo(val.lastTimestamp)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center" style={{ minWidth: '120px' }}>
                                                <div className="yellow-text fw-bold" style={{ fontSize: '1.2rem' }}>
                                                    {prettifyTokenAmount(val.xp, 18)}
                                                </div>
                                                <div className="white-muted-text" style={{ fontSize: '0.8rem' }}>XP Points</div>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default LeaderboardPage;
