/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as TokenContractJson } from "../onion/Token.ral.json";
import { getContractByCodeHash } from "./contracts";
import { TokenMetaData, UserProfile, AllStructs } from "./types";

// Custom types for the contract
export namespace TokenTypes {
  export type Fields = {
    symbol: HexString;
    name: HexString;
    decimals: bigint;
    supply: bigint;
    distributionEpochLength: bigint;
    supplyDistributionWindowInEpochs: bigint;
    distributeCaller: Address;
    lastDistributionEpoch: bigint;
    balance: bigint;
  };

  export type State = ContractState<Fields>;

  export type InitEvent = ContractEvent<{
    distributeCaller: Address;
    tgeAmount: bigint;
    startingEpoch: bigint;
  }>;
  export type DistributeEvent = ContractEvent<{ amount: bigint }>;
  export type TopUpEvent = ContractEvent<{ amount: bigint; caller: Address }>;

  export interface CallMethodTable {
    getSymbol: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    getName: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    getDecimals: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getTotalSupply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    init: {
      params: CallContractParams<{
        newDistributeCaller: Address;
        tgeAmount: bigint;
      }>;
      result: CallContractResult<null>;
    };
    distribute: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    topUp: {
      params: CallContractParams<{ amount: bigint }>;
      result: CallContractResult<bigint>;
    };
    getMaxSupply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getBalance: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getDistributeCaller: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getDistributionEpochLength: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getSupplyDistributionWindowInEpochs: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getLastDistributionEpoch: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    getSymbol: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getName: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getDecimals: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getTotalSupply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    init: {
      params: SignExecuteContractMethodParams<{
        newDistributeCaller: Address;
        tgeAmount: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
    distribute: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    topUp: {
      params: SignExecuteContractMethodParams<{ amount: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    getMaxSupply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getBalance: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getDistributeCaller: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getDistributionEpochLength: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getSupplyDistributionWindowInEpochs: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getLastDistributionEpoch: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<TokenInstance, TokenTypes.Fields> {
  encodeFields(fields: TokenTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = { Init: 0, Distribute: 1, TopUp: 2 };
  consts = {
    TokenErrorCodes: {
      InvalidDistributeCaller: BigInt("10000"),
      EpochAlreadyDistributed: BigInt("10001"),
      InvalidTopUpAmount: BigInt("10002"),
      ContractAlreadyInitialized: BigInt("10003"),
      ContractNotInitialized: BigInt("10004"),
      InvalidTGEAmount: BigInt("10005"),
    },
  };

  at(address: string): TokenInstance {
    return new TokenInstance(address);
  }

  tests = {
    getSymbol: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getSymbol", params, getContractByCodeHash);
    },
    getName: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getName", params, getContractByCodeHash);
    },
    getDecimals: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getDecimals", params, getContractByCodeHash);
    },
    getTotalSupply: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getTotalSupply", params, getContractByCodeHash);
    },
    init: async (
      params: TestContractParamsWithoutMaps<
        TokenTypes.Fields,
        { newDistributeCaller: Address; tgeAmount: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "init", params, getContractByCodeHash);
    },
    distribute: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "distribute", params, getContractByCodeHash);
    },
    topUp: async (
      params: TestContractParamsWithoutMaps<
        TokenTypes.Fields,
        { amount: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "topUp", params, getContractByCodeHash);
    },
    getMaxSupply: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getMaxSupply", params, getContractByCodeHash);
    },
    getBalance: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getBalance", params, getContractByCodeHash);
    },
    getDistributeCaller: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(
        this,
        "getDistributeCaller",
        params,
        getContractByCodeHash
      );
    },
    getDistributionEpochLength: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getDistributionEpochLength",
        params,
        getContractByCodeHash
      );
    },
    getSupplyDistributionWindowInEpochs: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getSupplyDistributionWindowInEpochs",
        params,
        getContractByCodeHash
      );
    },
    getLastDistributionEpoch: async (
      params: Omit<
        TestContractParamsWithoutMaps<TokenTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getLastDistributionEpoch",
        params,
        getContractByCodeHash
      );
    },
  };

  stateForTest(initFields: TokenTypes.Fields, asset?: Asset, address?: string) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const Token = new Factory(
  Contract.fromJson(
    TokenContractJson,
    "",
    "e0183cd856fb18dfd0abf52ec996eb513569390180fd617d37d4912e383295f2",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class TokenInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<TokenTypes.State> {
    return fetchContractState(Token, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeInitEvent(
    options: EventSubscribeOptions<TokenTypes.InitEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      Token.contract,
      this,
      options,
      "Init",
      fromCount
    );
  }

  subscribeDistributeEvent(
    options: EventSubscribeOptions<TokenTypes.DistributeEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      Token.contract,
      this,
      options,
      "Distribute",
      fromCount
    );
  }

  subscribeTopUpEvent(
    options: EventSubscribeOptions<TokenTypes.TopUpEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      Token.contract,
      this,
      options,
      "TopUp",
      fromCount
    );
  }

  subscribeAllEvents(
    options: EventSubscribeOptions<
      TokenTypes.InitEvent | TokenTypes.DistributeEvent | TokenTypes.TopUpEvent
    >,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvents(Token.contract, this, options, fromCount);
  }

  view = {
    getSymbol: async (
      params?: TokenTypes.CallMethodParams<"getSymbol">
    ): Promise<TokenTypes.CallMethodResult<"getSymbol">> => {
      return callMethod(
        Token,
        this,
        "getSymbol",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getName: async (
      params?: TokenTypes.CallMethodParams<"getName">
    ): Promise<TokenTypes.CallMethodResult<"getName">> => {
      return callMethod(
        Token,
        this,
        "getName",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getDecimals: async (
      params?: TokenTypes.CallMethodParams<"getDecimals">
    ): Promise<TokenTypes.CallMethodResult<"getDecimals">> => {
      return callMethod(
        Token,
        this,
        "getDecimals",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getTotalSupply: async (
      params?: TokenTypes.CallMethodParams<"getTotalSupply">
    ): Promise<TokenTypes.CallMethodResult<"getTotalSupply">> => {
      return callMethod(
        Token,
        this,
        "getTotalSupply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    init: async (
      params: TokenTypes.CallMethodParams<"init">
    ): Promise<TokenTypes.CallMethodResult<"init">> => {
      return callMethod(Token, this, "init", params, getContractByCodeHash);
    },
    distribute: async (
      params?: TokenTypes.CallMethodParams<"distribute">
    ): Promise<TokenTypes.CallMethodResult<"distribute">> => {
      return callMethod(
        Token,
        this,
        "distribute",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    topUp: async (
      params: TokenTypes.CallMethodParams<"topUp">
    ): Promise<TokenTypes.CallMethodResult<"topUp">> => {
      return callMethod(Token, this, "topUp", params, getContractByCodeHash);
    },
    getMaxSupply: async (
      params?: TokenTypes.CallMethodParams<"getMaxSupply">
    ): Promise<TokenTypes.CallMethodResult<"getMaxSupply">> => {
      return callMethod(
        Token,
        this,
        "getMaxSupply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getBalance: async (
      params?: TokenTypes.CallMethodParams<"getBalance">
    ): Promise<TokenTypes.CallMethodResult<"getBalance">> => {
      return callMethod(
        Token,
        this,
        "getBalance",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getDistributeCaller: async (
      params?: TokenTypes.CallMethodParams<"getDistributeCaller">
    ): Promise<TokenTypes.CallMethodResult<"getDistributeCaller">> => {
      return callMethod(
        Token,
        this,
        "getDistributeCaller",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getDistributionEpochLength: async (
      params?: TokenTypes.CallMethodParams<"getDistributionEpochLength">
    ): Promise<TokenTypes.CallMethodResult<"getDistributionEpochLength">> => {
      return callMethod(
        Token,
        this,
        "getDistributionEpochLength",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getSupplyDistributionWindowInEpochs: async (
      params?: TokenTypes.CallMethodParams<"getSupplyDistributionWindowInEpochs">
    ): Promise<
      TokenTypes.CallMethodResult<"getSupplyDistributionWindowInEpochs">
    > => {
      return callMethod(
        Token,
        this,
        "getSupplyDistributionWindowInEpochs",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getLastDistributionEpoch: async (
      params?: TokenTypes.CallMethodParams<"getLastDistributionEpoch">
    ): Promise<TokenTypes.CallMethodResult<"getLastDistributionEpoch">> => {
      return callMethod(
        Token,
        this,
        "getLastDistributionEpoch",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    getSymbol: async (
      params: TokenTypes.SignExecuteMethodParams<"getSymbol">
    ): Promise<TokenTypes.SignExecuteMethodResult<"getSymbol">> => {
      return signExecuteMethod(Token, this, "getSymbol", params);
    },
    getName: async (
      params: TokenTypes.SignExecuteMethodParams<"getName">
    ): Promise<TokenTypes.SignExecuteMethodResult<"getName">> => {
      return signExecuteMethod(Token, this, "getName", params);
    },
    getDecimals: async (
      params: TokenTypes.SignExecuteMethodParams<"getDecimals">
    ): Promise<TokenTypes.SignExecuteMethodResult<"getDecimals">> => {
      return signExecuteMethod(Token, this, "getDecimals", params);
    },
    getTotalSupply: async (
      params: TokenTypes.SignExecuteMethodParams<"getTotalSupply">
    ): Promise<TokenTypes.SignExecuteMethodResult<"getTotalSupply">> => {
      return signExecuteMethod(Token, this, "getTotalSupply", params);
    },
    init: async (
      params: TokenTypes.SignExecuteMethodParams<"init">
    ): Promise<TokenTypes.SignExecuteMethodResult<"init">> => {
      return signExecuteMethod(Token, this, "init", params);
    },
    distribute: async (
      params: TokenTypes.SignExecuteMethodParams<"distribute">
    ): Promise<TokenTypes.SignExecuteMethodResult<"distribute">> => {
      return signExecuteMethod(Token, this, "distribute", params);
    },
    topUp: async (
      params: TokenTypes.SignExecuteMethodParams<"topUp">
    ): Promise<TokenTypes.SignExecuteMethodResult<"topUp">> => {
      return signExecuteMethod(Token, this, "topUp", params);
    },
    getMaxSupply: async (
      params: TokenTypes.SignExecuteMethodParams<"getMaxSupply">
    ): Promise<TokenTypes.SignExecuteMethodResult<"getMaxSupply">> => {
      return signExecuteMethod(Token, this, "getMaxSupply", params);
    },
    getBalance: async (
      params: TokenTypes.SignExecuteMethodParams<"getBalance">
    ): Promise<TokenTypes.SignExecuteMethodResult<"getBalance">> => {
      return signExecuteMethod(Token, this, "getBalance", params);
    },
    getDistributeCaller: async (
      params: TokenTypes.SignExecuteMethodParams<"getDistributeCaller">
    ): Promise<TokenTypes.SignExecuteMethodResult<"getDistributeCaller">> => {
      return signExecuteMethod(Token, this, "getDistributeCaller", params);
    },
    getDistributionEpochLength: async (
      params: TokenTypes.SignExecuteMethodParams<"getDistributionEpochLength">
    ): Promise<
      TokenTypes.SignExecuteMethodResult<"getDistributionEpochLength">
    > => {
      return signExecuteMethod(
        Token,
        this,
        "getDistributionEpochLength",
        params
      );
    },
    getSupplyDistributionWindowInEpochs: async (
      params: TokenTypes.SignExecuteMethodParams<"getSupplyDistributionWindowInEpochs">
    ): Promise<
      TokenTypes.SignExecuteMethodResult<"getSupplyDistributionWindowInEpochs">
    > => {
      return signExecuteMethod(
        Token,
        this,
        "getSupplyDistributionWindowInEpochs",
        params
      );
    },
    getLastDistributionEpoch: async (
      params: TokenTypes.SignExecuteMethodParams<"getLastDistributionEpoch">
    ): Promise<
      TokenTypes.SignExecuteMethodResult<"getLastDistributionEpoch">
    > => {
      return signExecuteMethod(Token, this, "getLastDistributionEpoch", params);
    },
  };

  async multicall<Calls extends TokenTypes.MultiCallParams>(
    calls: Calls
  ): Promise<TokenTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends TokenTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<TokenTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends TokenTypes.MultiCallParams | TokenTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(Token, this, callss, getContractByCodeHash);
  }
}
