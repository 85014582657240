/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as BondingPairContractJson } from "../trade/BondingPair.ral.json";
import { getContractByCodeHash } from "./contracts";
import { TokenMetaData, UserProfile, AllStructs } from "./types";

// Custom types for the contract
export namespace BondingPairTypes {
  export type Fields = {
    tokenLauncher: HexString;
    router: HexString;
    feeCollector: HexString;
    tokenId: HexString;
    bidTokenId: HexString;
    totalTokens: bigint;
    maxTokens: bigint;
    creatorAddress: Address;
    k: bigint;
    a: bigint;
    tokensSold: bigint;
    totalBidToken: bigint;
    graduated: boolean;
  };

  export type State = ContractState<Fields>;

  export type TradeEvent = ContractEvent<{
    caller: Address;
    tokenAmount: bigint;
    bidTokenAmount: bigint;
  }>;

  export interface CallMethodTable {
    exp: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    pow2: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    expFractional: {
      params: CallContractParams<{ f: bigint }>;
      result: CallContractResult<bigint>;
    };
    ln: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    log2: {
      params: CallContractParams<{ value: bigint }>;
      result: CallContractResult<bigint>;
    };
    scaleToRange: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<[bigint, bigint]>;
    };
    getTokenId: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    isGraduated: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<boolean>;
    };
    isReadyToGraduate: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<boolean>;
    };
    sell: {
      params: CallContractParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyRecipient: Address;
      }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    calcSell: {
      params: CallContractParams<{ tokenAmountIn: bigint }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    buy: {
      params: CallContractParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyRecipient: Address;
      }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    calcBuy: {
      params: CallContractParams<{ bidTokenAmountIn: bigint }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    totalPriceOfTokens: {
      params: CallContractParams<{ startToken: bigint; endToken: bigint }>;
      result: CallContractResult<bigint>;
    };
    graduate: {
      params: CallContractParams<{
        dexPairTemplateId: HexString;
        royaltyRecipient: Address;
      }>;
      result: CallContractResult<HexString>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    exp: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    pow2: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    expFractional: {
      params: SignExecuteContractMethodParams<{ f: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    ln: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    log2: {
      params: SignExecuteContractMethodParams<{ value: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    scaleToRange: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    getTokenId: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    isGraduated: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    isReadyToGraduate: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    sell: {
      params: SignExecuteContractMethodParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyRecipient: Address;
      }>;
      result: SignExecuteScriptTxResult;
    };
    calcSell: {
      params: SignExecuteContractMethodParams<{ tokenAmountIn: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    buy: {
      params: SignExecuteContractMethodParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyRecipient: Address;
      }>;
      result: SignExecuteScriptTxResult;
    };
    calcBuy: {
      params: SignExecuteContractMethodParams<{ bidTokenAmountIn: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    totalPriceOfTokens: {
      params: SignExecuteContractMethodParams<{
        startToken: bigint;
        endToken: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
    graduate: {
      params: SignExecuteContractMethodParams<{
        dexPairTemplateId: HexString;
        royaltyRecipient: Address;
      }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<
  BondingPairInstance,
  BondingPairTypes.Fields
> {
  encodeFields(fields: BondingPairTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = { Trade: 0 };
  consts = {
    SCALE: BigInt("1000000000000000000"),
    LN2: BigInt("693147180559945309"),
    BASIS_POINTS_DIVISOR: BigInt("10000"),
    ROYALTY_FEE: BigInt("15"),
    PLATFORM_FEE: BigInt("85"),
    GRADUATION_FEE: BigInt("100000000000000000000"),
    FixedPointMathErrorCodes: {
      INVALID_INPUT: BigInt("100000"),
      OVERFLOW: BigInt("100001"),
      INVALID_SCALING: BigInt("100003"),
    },
    ErrorCodes: {
      Unauthorized: BigInt("1"),
      TooEarlyToGraduate: BigInt("2"),
      ReadyToGraduate: BigInt("3"),
      CreatorBuysFirst: BigInt("4"),
      NotEnoughTokensLeft: BigInt("5"),
      InvalidTokenSelection: BigInt("6"),
    },
  };

  at(address: string): BondingPairInstance {
    return new BondingPairInstance(address);
  }

  tests = {
    exp: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "exp", params, getContractByCodeHash);
    },
    pow2: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "pow2", params, getContractByCodeHash);
    },
    expFractional: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { f: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "expFractional", params, getContractByCodeHash);
    },
    ln: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "ln", params, getContractByCodeHash);
    },
    log2: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { value: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "log2", params, getContractByCodeHash);
    },
    scaleToRange: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint]>> => {
      return testMethod(this, "scaleToRange", params, getContractByCodeHash);
    },
    getTokenId: async (
      params: Omit<
        TestContractParamsWithoutMaps<BondingPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getTokenId", params, getContractByCodeHash);
    },
    isGraduated: async (
      params: Omit<
        TestContractParamsWithoutMaps<BondingPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<boolean>> => {
      return testMethod(this, "isGraduated", params, getContractByCodeHash);
    },
    isReadyToGraduate: async (
      params: Omit<
        TestContractParamsWithoutMaps<BondingPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<boolean>> => {
      return testMethod(
        this,
        "isReadyToGraduate",
        params,
        getContractByCodeHash
      );
    },
    sell: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { caller: Address; tokenAmountIn: bigint; royaltyRecipient: Address }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "sell", params, getContractByCodeHash);
    },
    calcSell: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { tokenAmountIn: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "calcSell", params, getContractByCodeHash);
    },
    buy: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { caller: Address; tokenAmountIn: bigint; royaltyRecipient: Address }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "buy", params, getContractByCodeHash);
    },
    calcBuy: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { bidTokenAmountIn: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "calcBuy", params, getContractByCodeHash);
    },
    totalPriceOfTokens: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { startToken: bigint; endToken: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "totalPriceOfTokens",
        params,
        getContractByCodeHash
      );
    },
    graduate: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { dexPairTemplateId: HexString; royaltyRecipient: Address }
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "graduate", params, getContractByCodeHash);
    },
  };

  stateForTest(
    initFields: BondingPairTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const BondingPair = new Factory(
  Contract.fromJson(
    BondingPairContractJson,
    "=9+1=1-2+c=1-4+2=1-1=3-2+66=2+5c=1-1=1-2+d=3-1+e=3-4=1-1+9=2+11=1-1+589=3-1+9=2-2+69=3-1+a=2+734=1-1+1a=138-2+4024=173+0160116021603160416057e070a504f5732202d20783a200f2c20696e7465676572506172743a20122c206672616374696f6e616c506172743a200d2c20696e74526573756c743a200e2c2066726163526573756c743a20122c20636f6d62696e6564526573756c743a2000160=584-1+e=46+1600160216017e0403783a20052c206e3a200c2c20785f7363616c65643a2000=2920",
    "e60cf2474d439ba083fdfc3369958cef72d96c9dcb600f22b30358dc91c58777",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class BondingPairInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<BondingPairTypes.State> {
    return fetchContractState(BondingPair, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeTradeEvent(
    options: EventSubscribeOptions<BondingPairTypes.TradeEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      BondingPair.contract,
      this,
      options,
      "Trade",
      fromCount
    );
  }

  view = {
    exp: async (
      params: BondingPairTypes.CallMethodParams<"exp">
    ): Promise<BondingPairTypes.CallMethodResult<"exp">> => {
      return callMethod(
        BondingPair,
        this,
        "exp",
        params,
        getContractByCodeHash
      );
    },
    pow2: async (
      params: BondingPairTypes.CallMethodParams<"pow2">
    ): Promise<BondingPairTypes.CallMethodResult<"pow2">> => {
      return callMethod(
        BondingPair,
        this,
        "pow2",
        params,
        getContractByCodeHash
      );
    },
    expFractional: async (
      params: BondingPairTypes.CallMethodParams<"expFractional">
    ): Promise<BondingPairTypes.CallMethodResult<"expFractional">> => {
      return callMethod(
        BondingPair,
        this,
        "expFractional",
        params,
        getContractByCodeHash
      );
    },
    ln: async (
      params: BondingPairTypes.CallMethodParams<"ln">
    ): Promise<BondingPairTypes.CallMethodResult<"ln">> => {
      return callMethod(BondingPair, this, "ln", params, getContractByCodeHash);
    },
    log2: async (
      params: BondingPairTypes.CallMethodParams<"log2">
    ): Promise<BondingPairTypes.CallMethodResult<"log2">> => {
      return callMethod(
        BondingPair,
        this,
        "log2",
        params,
        getContractByCodeHash
      );
    },
    scaleToRange: async (
      params: BondingPairTypes.CallMethodParams<"scaleToRange">
    ): Promise<BondingPairTypes.CallMethodResult<"scaleToRange">> => {
      return callMethod(
        BondingPair,
        this,
        "scaleToRange",
        params,
        getContractByCodeHash
      );
    },
    getTokenId: async (
      params?: BondingPairTypes.CallMethodParams<"getTokenId">
    ): Promise<BondingPairTypes.CallMethodResult<"getTokenId">> => {
      return callMethod(
        BondingPair,
        this,
        "getTokenId",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    isGraduated: async (
      params?: BondingPairTypes.CallMethodParams<"isGraduated">
    ): Promise<BondingPairTypes.CallMethodResult<"isGraduated">> => {
      return callMethod(
        BondingPair,
        this,
        "isGraduated",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    isReadyToGraduate: async (
      params?: BondingPairTypes.CallMethodParams<"isReadyToGraduate">
    ): Promise<BondingPairTypes.CallMethodResult<"isReadyToGraduate">> => {
      return callMethod(
        BondingPair,
        this,
        "isReadyToGraduate",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    sell: async (
      params: BondingPairTypes.CallMethodParams<"sell">
    ): Promise<BondingPairTypes.CallMethodResult<"sell">> => {
      return callMethod(
        BondingPair,
        this,
        "sell",
        params,
        getContractByCodeHash
      );
    },
    calcSell: async (
      params: BondingPairTypes.CallMethodParams<"calcSell">
    ): Promise<BondingPairTypes.CallMethodResult<"calcSell">> => {
      return callMethod(
        BondingPair,
        this,
        "calcSell",
        params,
        getContractByCodeHash
      );
    },
    buy: async (
      params: BondingPairTypes.CallMethodParams<"buy">
    ): Promise<BondingPairTypes.CallMethodResult<"buy">> => {
      return callMethod(
        BondingPair,
        this,
        "buy",
        params,
        getContractByCodeHash
      );
    },
    calcBuy: async (
      params: BondingPairTypes.CallMethodParams<"calcBuy">
    ): Promise<BondingPairTypes.CallMethodResult<"calcBuy">> => {
      return callMethod(
        BondingPair,
        this,
        "calcBuy",
        params,
        getContractByCodeHash
      );
    },
    totalPriceOfTokens: async (
      params: BondingPairTypes.CallMethodParams<"totalPriceOfTokens">
    ): Promise<BondingPairTypes.CallMethodResult<"totalPriceOfTokens">> => {
      return callMethod(
        BondingPair,
        this,
        "totalPriceOfTokens",
        params,
        getContractByCodeHash
      );
    },
    graduate: async (
      params: BondingPairTypes.CallMethodParams<"graduate">
    ): Promise<BondingPairTypes.CallMethodResult<"graduate">> => {
      return callMethod(
        BondingPair,
        this,
        "graduate",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    exp: async (
      params: BondingPairTypes.SignExecuteMethodParams<"exp">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"exp">> => {
      return signExecuteMethod(BondingPair, this, "exp", params);
    },
    pow2: async (
      params: BondingPairTypes.SignExecuteMethodParams<"pow2">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"pow2">> => {
      return signExecuteMethod(BondingPair, this, "pow2", params);
    },
    expFractional: async (
      params: BondingPairTypes.SignExecuteMethodParams<"expFractional">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"expFractional">> => {
      return signExecuteMethod(BondingPair, this, "expFractional", params);
    },
    ln: async (
      params: BondingPairTypes.SignExecuteMethodParams<"ln">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"ln">> => {
      return signExecuteMethod(BondingPair, this, "ln", params);
    },
    log2: async (
      params: BondingPairTypes.SignExecuteMethodParams<"log2">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"log2">> => {
      return signExecuteMethod(BondingPair, this, "log2", params);
    },
    scaleToRange: async (
      params: BondingPairTypes.SignExecuteMethodParams<"scaleToRange">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"scaleToRange">> => {
      return signExecuteMethod(BondingPair, this, "scaleToRange", params);
    },
    getTokenId: async (
      params: BondingPairTypes.SignExecuteMethodParams<"getTokenId">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"getTokenId">> => {
      return signExecuteMethod(BondingPair, this, "getTokenId", params);
    },
    isGraduated: async (
      params: BondingPairTypes.SignExecuteMethodParams<"isGraduated">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"isGraduated">> => {
      return signExecuteMethod(BondingPair, this, "isGraduated", params);
    },
    isReadyToGraduate: async (
      params: BondingPairTypes.SignExecuteMethodParams<"isReadyToGraduate">
    ): Promise<
      BondingPairTypes.SignExecuteMethodResult<"isReadyToGraduate">
    > => {
      return signExecuteMethod(BondingPair, this, "isReadyToGraduate", params);
    },
    sell: async (
      params: BondingPairTypes.SignExecuteMethodParams<"sell">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"sell">> => {
      return signExecuteMethod(BondingPair, this, "sell", params);
    },
    calcSell: async (
      params: BondingPairTypes.SignExecuteMethodParams<"calcSell">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"calcSell">> => {
      return signExecuteMethod(BondingPair, this, "calcSell", params);
    },
    buy: async (
      params: BondingPairTypes.SignExecuteMethodParams<"buy">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"buy">> => {
      return signExecuteMethod(BondingPair, this, "buy", params);
    },
    calcBuy: async (
      params: BondingPairTypes.SignExecuteMethodParams<"calcBuy">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"calcBuy">> => {
      return signExecuteMethod(BondingPair, this, "calcBuy", params);
    },
    totalPriceOfTokens: async (
      params: BondingPairTypes.SignExecuteMethodParams<"totalPriceOfTokens">
    ): Promise<
      BondingPairTypes.SignExecuteMethodResult<"totalPriceOfTokens">
    > => {
      return signExecuteMethod(BondingPair, this, "totalPriceOfTokens", params);
    },
    graduate: async (
      params: BondingPairTypes.SignExecuteMethodParams<"graduate">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"graduate">> => {
      return signExecuteMethod(BondingPair, this, "graduate", params);
    },
  };

  async multicall<Calls extends BondingPairTypes.MultiCallParams>(
    calls: Calls
  ): Promise<BondingPairTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends BondingPairTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<BondingPairTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | BondingPairTypes.MultiCallParams
      | BondingPairTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      BondingPair,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
