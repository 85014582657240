import { ApadContext } from '../../context/AppContext';
import { web3 } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import { useContext, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Link } from 'react-router-dom';

function Toaster() {
    const { nodeProvider } = useWallet()
    if (nodeProvider) {
        web3.setCurrentNodeProvider(nodeProvider)
    }
    const { transactions, setTransactions } = useContext(ApadContext);

    useEffect(() => {
        if (nodeProvider) {
            const interval = setInterval(() => {
                transactions.forEach(async (transaction, index) => {
                    if (transaction.status !== "Confirmed") {
                        const txStatus = await nodeProvider.transactions.getTransactionsStatus({ txId: transaction.txId });
                        if (txStatus.type === "Confirmed") {
                            setTransactions(currentTransactions =>
                                currentTransactions.map((t) => t.txId === transaction.txId ? { ...t, status: txStatus.type } : t)
                            );
                        }
                    } else {
                        setTransactions(currentTransactions =>
                            currentTransactions.filter((t) => t.txId !== transaction.txId)
                        );
                    }
                });
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [transactions, setTransactions, nodeProvider]);


    return (
        <ToastContainer position="top-end" className="p-3" containerPosition="fixed" style={{ zIndex: 1, marginTop: "80px" }}>
            {transactions.map((transaction, index) => (
                <Toast key={`${transaction.txId}-${transaction.status}`} style={{ border: "none", backgroundColor: "#0A0202" }}>
                    <Toast.Header style={{ backgroundColor: "#ff363710", color: "#ccc" }} closeVariant='white'>
                        <strong className="me-auto">{transaction.description}</strong>
                        <small>{transaction.status}</small>
                    </Toast.Header>
                    <Toast.Body style={{ color: "#ccc" }}><Link target='_blank' to={`${process.env.NEXT_PUBLIC_EXPLORER_URL}/transactions/${transaction.txId}`}>{transaction.txId}</Link></Toast.Body>
                </Toast>
            ))}
        </ToastContainer >
    );
}

export default Toaster;