/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as FeeCollectorContractJson } from "../onion/FeeCollector.ral.json";
import { getContractByCodeHash } from "./contracts";
import { TokenMetaData, UserProfile, AllStructs } from "./types";

// Custom types for the contract
export namespace FeeCollectorTypes {
  export type Fields = {
    upgradeDelay: bigint;
    tradeFeeCollected: bigint;
    owner: Address;
    newOwner: Address;
    upgradeCommenced: bigint;
    newCode: HexString;
  };

  export type State = ContractState<Fields>;

  export type ChangeOwnerCommenceEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type ChangeOwnerApplyEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type MigrateCommenceEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateWithFieldsApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
    changeImmFieldsEncoded: HexString;
    changeMutFieldsEncoded: HexString;
  }>;
  export type DepositFeeEvent = ContractEvent<{
    contractAddress: Address;
    user: Address;
    caller: Address;
    feeAmount: bigint;
  }>;

  export interface CallMethodTable {
    changeOwner: {
      params: CallContractParams<{ changeOwner: Address }>;
      result: CallContractResult<null>;
    };
    migrate: {
      params: CallContractParams<{ changeCode: HexString }>;
      result: CallContractResult<null>;
    };
    changeOwnerApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateWithFieldsApply: {
      params: CallContractParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: CallContractResult<null>;
    };
    resetUpgrade: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    getUpgradeDelay: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getNewOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getUpgradeCommenced: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getNewCode: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    resetFields: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertOnlyOwner: {
      params: CallContractParams<{ caller: Address }>;
      result: CallContractResult<null>;
    };
    assertUpgradeNotPending: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    depositFee: {
      params: CallContractParams<{
        contractAddress: Address;
        caller: Address;
        user: Address;
        feeAmount: bigint;
      }>;
      result: CallContractResult<null>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    changeOwner: {
      params: SignExecuteContractMethodParams<{ changeOwner: Address }>;
      result: SignExecuteScriptTxResult;
    };
    migrate: {
      params: SignExecuteContractMethodParams<{ changeCode: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    changeOwnerApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateWithFieldsApply: {
      params: SignExecuteContractMethodParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    resetUpgrade: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeDelay: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeCommenced: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewCode: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    resetFields: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertOnlyOwner: {
      params: SignExecuteContractMethodParams<{ caller: Address }>;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeNotPending: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    depositFee: {
      params: SignExecuteContractMethodParams<{
        contractAddress: Address;
        caller: Address;
        user: Address;
        feeAmount: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<
  FeeCollectorInstance,
  FeeCollectorTypes.Fields
> {
  encodeFields(fields: FeeCollectorTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = {
    ChangeOwnerCommence: 0,
    ChangeOwnerApply: 1,
    MigrateCommence: 2,
    MigrateApply: 3,
    MigrateWithFieldsApply: 4,
    DepositFee: 5,
  };
  consts = {
    UpgradeErrorCodes: {
      Forbidden: BigInt("13000"),
      UpgradePending: BigInt("13001"),
      UpgradeNotPending: BigInt("13002"),
      UpgradeDelayNotExpired: BigInt("13003"),
      MigrateNotPending: BigInt("13004"),
      MigrateWithFieldsNotPending: BigInt("13005"),
      ChangeOwnerNotPending: BigInt("13006"),
    },
  };

  at(address: string): FeeCollectorInstance {
    return new FeeCollectorInstance(address);
  }

  tests = {
    changeOwner: async (
      params: TestContractParamsWithoutMaps<
        FeeCollectorTypes.Fields,
        { changeOwner: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "changeOwner", params, getContractByCodeHash);
    },
    migrate: async (
      params: TestContractParamsWithoutMaps<
        FeeCollectorTypes.Fields,
        { changeCode: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrate", params, getContractByCodeHash);
    },
    changeOwnerApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "changeOwnerApply",
        params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrateApply", params, getContractByCodeHash);
    },
    migrateWithFieldsApply: async (
      params: TestContractParamsWithoutMaps<
        FeeCollectorTypes.Fields,
        { newImmFieldsEncoded: HexString; newMutFieldsEncoded: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetUpgrade", params, getContractByCodeHash);
    },
    getUpgradeDelay: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getUpgradeDelay", params, getContractByCodeHash);
    },
    getOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getOwner", params, getContractByCodeHash);
    },
    getNewOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getNewOwner", params, getContractByCodeHash);
    },
    getUpgradeCommenced: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getUpgradeCommenced",
        params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getNewCode", params, getContractByCodeHash);
    },
    resetFields: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetFields", params, getContractByCodeHash);
    },
    assertOnlyOwner: async (
      params: TestContractParamsWithoutMaps<
        FeeCollectorTypes.Fields,
        { caller: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "assertOnlyOwner", params, getContractByCodeHash);
    },
    assertUpgradeNotPending: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeNotPending",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeCollectorTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeDelayElapsed",
        params,
        getContractByCodeHash
      );
    },
    depositFee: async (
      params: TestContractParamsWithoutMaps<
        FeeCollectorTypes.Fields,
        {
          contractAddress: Address;
          caller: Address;
          user: Address;
          feeAmount: bigint;
        }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "depositFee", params, getContractByCodeHash);
    },
  };

  stateForTest(
    initFields: FeeCollectorTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const FeeCollector = new Factory(
  Contract.fromJson(
    FeeCollectorContractJson,
    "",
    "8e1469b52675f519fbba5125270a707d31965c789148627d1be5eedc6aaeeb76",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class FeeCollectorInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<FeeCollectorTypes.State> {
    return fetchContractState(FeeCollector, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeChangeOwnerCommenceEvent(
    options: EventSubscribeOptions<FeeCollectorTypes.ChangeOwnerCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeCollector.contract,
      this,
      options,
      "ChangeOwnerCommence",
      fromCount
    );
  }

  subscribeChangeOwnerApplyEvent(
    options: EventSubscribeOptions<FeeCollectorTypes.ChangeOwnerApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeCollector.contract,
      this,
      options,
      "ChangeOwnerApply",
      fromCount
    );
  }

  subscribeMigrateCommenceEvent(
    options: EventSubscribeOptions<FeeCollectorTypes.MigrateCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeCollector.contract,
      this,
      options,
      "MigrateCommence",
      fromCount
    );
  }

  subscribeMigrateApplyEvent(
    options: EventSubscribeOptions<FeeCollectorTypes.MigrateApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeCollector.contract,
      this,
      options,
      "MigrateApply",
      fromCount
    );
  }

  subscribeMigrateWithFieldsApplyEvent(
    options: EventSubscribeOptions<FeeCollectorTypes.MigrateWithFieldsApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeCollector.contract,
      this,
      options,
      "MigrateWithFieldsApply",
      fromCount
    );
  }

  subscribeDepositFeeEvent(
    options: EventSubscribeOptions<FeeCollectorTypes.DepositFeeEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeCollector.contract,
      this,
      options,
      "DepositFee",
      fromCount
    );
  }

  subscribeAllEvents(
    options: EventSubscribeOptions<
      | FeeCollectorTypes.ChangeOwnerCommenceEvent
      | FeeCollectorTypes.ChangeOwnerApplyEvent
      | FeeCollectorTypes.MigrateCommenceEvent
      | FeeCollectorTypes.MigrateApplyEvent
      | FeeCollectorTypes.MigrateWithFieldsApplyEvent
      | FeeCollectorTypes.DepositFeeEvent
    >,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvents(
      FeeCollector.contract,
      this,
      options,
      fromCount
    );
  }

  view = {
    changeOwner: async (
      params: FeeCollectorTypes.CallMethodParams<"changeOwner">
    ): Promise<FeeCollectorTypes.CallMethodResult<"changeOwner">> => {
      return callMethod(
        FeeCollector,
        this,
        "changeOwner",
        params,
        getContractByCodeHash
      );
    },
    migrate: async (
      params: FeeCollectorTypes.CallMethodParams<"migrate">
    ): Promise<FeeCollectorTypes.CallMethodResult<"migrate">> => {
      return callMethod(
        FeeCollector,
        this,
        "migrate",
        params,
        getContractByCodeHash
      );
    },
    changeOwnerApply: async (
      params?: FeeCollectorTypes.CallMethodParams<"changeOwnerApply">
    ): Promise<FeeCollectorTypes.CallMethodResult<"changeOwnerApply">> => {
      return callMethod(
        FeeCollector,
        this,
        "changeOwnerApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params?: FeeCollectorTypes.CallMethodParams<"migrateApply">
    ): Promise<FeeCollectorTypes.CallMethodResult<"migrateApply">> => {
      return callMethod(
        FeeCollector,
        this,
        "migrateApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateWithFieldsApply: async (
      params: FeeCollectorTypes.CallMethodParams<"migrateWithFieldsApply">
    ): Promise<
      FeeCollectorTypes.CallMethodResult<"migrateWithFieldsApply">
    > => {
      return callMethod(
        FeeCollector,
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params?: FeeCollectorTypes.CallMethodParams<"resetUpgrade">
    ): Promise<FeeCollectorTypes.CallMethodResult<"resetUpgrade">> => {
      return callMethod(
        FeeCollector,
        this,
        "resetUpgrade",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeDelay: async (
      params?: FeeCollectorTypes.CallMethodParams<"getUpgradeDelay">
    ): Promise<FeeCollectorTypes.CallMethodResult<"getUpgradeDelay">> => {
      return callMethod(
        FeeCollector,
        this,
        "getUpgradeDelay",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getOwner: async (
      params?: FeeCollectorTypes.CallMethodParams<"getOwner">
    ): Promise<FeeCollectorTypes.CallMethodResult<"getOwner">> => {
      return callMethod(
        FeeCollector,
        this,
        "getOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewOwner: async (
      params?: FeeCollectorTypes.CallMethodParams<"getNewOwner">
    ): Promise<FeeCollectorTypes.CallMethodResult<"getNewOwner">> => {
      return callMethod(
        FeeCollector,
        this,
        "getNewOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeCommenced: async (
      params?: FeeCollectorTypes.CallMethodParams<"getUpgradeCommenced">
    ): Promise<FeeCollectorTypes.CallMethodResult<"getUpgradeCommenced">> => {
      return callMethod(
        FeeCollector,
        this,
        "getUpgradeCommenced",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params?: FeeCollectorTypes.CallMethodParams<"getNewCode">
    ): Promise<FeeCollectorTypes.CallMethodResult<"getNewCode">> => {
      return callMethod(
        FeeCollector,
        this,
        "getNewCode",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    resetFields: async (
      params?: FeeCollectorTypes.CallMethodParams<"resetFields">
    ): Promise<FeeCollectorTypes.CallMethodResult<"resetFields">> => {
      return callMethod(
        FeeCollector,
        this,
        "resetFields",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertOnlyOwner: async (
      params: FeeCollectorTypes.CallMethodParams<"assertOnlyOwner">
    ): Promise<FeeCollectorTypes.CallMethodResult<"assertOnlyOwner">> => {
      return callMethod(
        FeeCollector,
        this,
        "assertOnlyOwner",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeNotPending: async (
      params?: FeeCollectorTypes.CallMethodParams<"assertUpgradeNotPending">
    ): Promise<
      FeeCollectorTypes.CallMethodResult<"assertUpgradeNotPending">
    > => {
      return callMethod(
        FeeCollector,
        this,
        "assertUpgradeNotPending",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params?: FeeCollectorTypes.CallMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      FeeCollectorTypes.CallMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return callMethod(
        FeeCollector,
        this,
        "assertUpgradeDelayElapsed",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    depositFee: async (
      params: FeeCollectorTypes.CallMethodParams<"depositFee">
    ): Promise<FeeCollectorTypes.CallMethodResult<"depositFee">> => {
      return callMethod(
        FeeCollector,
        this,
        "depositFee",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    changeOwner: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"changeOwner">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"changeOwner">> => {
      return signExecuteMethod(FeeCollector, this, "changeOwner", params);
    },
    migrate: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"migrate">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"migrate">> => {
      return signExecuteMethod(FeeCollector, this, "migrate", params);
    },
    changeOwnerApply: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"changeOwnerApply">
    ): Promise<
      FeeCollectorTypes.SignExecuteMethodResult<"changeOwnerApply">
    > => {
      return signExecuteMethod(FeeCollector, this, "changeOwnerApply", params);
    },
    migrateApply: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"migrateApply">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"migrateApply">> => {
      return signExecuteMethod(FeeCollector, this, "migrateApply", params);
    },
    migrateWithFieldsApply: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"migrateWithFieldsApply">
    ): Promise<
      FeeCollectorTypes.SignExecuteMethodResult<"migrateWithFieldsApply">
    > => {
      return signExecuteMethod(
        FeeCollector,
        this,
        "migrateWithFieldsApply",
        params
      );
    },
    resetUpgrade: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"resetUpgrade">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"resetUpgrade">> => {
      return signExecuteMethod(FeeCollector, this, "resetUpgrade", params);
    },
    getUpgradeDelay: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"getUpgradeDelay">
    ): Promise<
      FeeCollectorTypes.SignExecuteMethodResult<"getUpgradeDelay">
    > => {
      return signExecuteMethod(FeeCollector, this, "getUpgradeDelay", params);
    },
    getOwner: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"getOwner">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"getOwner">> => {
      return signExecuteMethod(FeeCollector, this, "getOwner", params);
    },
    getNewOwner: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"getNewOwner">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"getNewOwner">> => {
      return signExecuteMethod(FeeCollector, this, "getNewOwner", params);
    },
    getUpgradeCommenced: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"getUpgradeCommenced">
    ): Promise<
      FeeCollectorTypes.SignExecuteMethodResult<"getUpgradeCommenced">
    > => {
      return signExecuteMethod(
        FeeCollector,
        this,
        "getUpgradeCommenced",
        params
      );
    },
    getNewCode: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"getNewCode">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"getNewCode">> => {
      return signExecuteMethod(FeeCollector, this, "getNewCode", params);
    },
    resetFields: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"resetFields">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"resetFields">> => {
      return signExecuteMethod(FeeCollector, this, "resetFields", params);
    },
    assertOnlyOwner: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"assertOnlyOwner">
    ): Promise<
      FeeCollectorTypes.SignExecuteMethodResult<"assertOnlyOwner">
    > => {
      return signExecuteMethod(FeeCollector, this, "assertOnlyOwner", params);
    },
    assertUpgradeNotPending: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"assertUpgradeNotPending">
    ): Promise<
      FeeCollectorTypes.SignExecuteMethodResult<"assertUpgradeNotPending">
    > => {
      return signExecuteMethod(
        FeeCollector,
        this,
        "assertUpgradeNotPending",
        params
      );
    },
    assertUpgradeDelayElapsed: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      FeeCollectorTypes.SignExecuteMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return signExecuteMethod(
        FeeCollector,
        this,
        "assertUpgradeDelayElapsed",
        params
      );
    },
    depositFee: async (
      params: FeeCollectorTypes.SignExecuteMethodParams<"depositFee">
    ): Promise<FeeCollectorTypes.SignExecuteMethodResult<"depositFee">> => {
      return signExecuteMethod(FeeCollector, this, "depositFee", params);
    },
  };

  async multicall<Calls extends FeeCollectorTypes.MultiCallParams>(
    calls: Calls
  ): Promise<FeeCollectorTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends FeeCollectorTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<FeeCollectorTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | FeeCollectorTypes.MultiCallParams
      | FeeCollectorTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      FeeCollector,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
