"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.genArgs = exports.DappTransactionBuilder = void 0;
const address_1 = require("../address");
const codec_1 = require("../codec");
const lockup_script_codec_1 = require("../codec/lockup-script-codec");
const script_codec_1 = require("../codec/script-codec");
const constants_1 = require("../constants");
const error_1 = require("../error");
const utils_1 = require("../utils");
class DappTransactionBuilder {
    constructor(callerAddress) {
        this.callerAddress = callerAddress;
        try {
            this.callerLockupScript = lockup_script_codec_1.lockupScriptCodec.decode((0, utils_1.base58ToBytes)(this.callerAddress));
            if (this.callerLockupScript.kind !== 'P2PKH' && this.callerLockupScript.kind !== 'P2SH') {
                throw new Error(`Expected a P2PKH address or P2SH address`);
            }
        }
        catch (error) {
            throw new error_1.TraceableError(`Invalid caller address: ${callerAddress}`, error);
        }
        this.approvedAssets = new Map();
        this.instrs = [];
    }
    callContract(params) {
        if (!(0, utils_1.isBase58)(params.contractAddress)) {
            throw new Error(`Invalid contract address: ${params.contractAddress}, expected a base58 string`);
        }
        if (!(0, address_1.isContractAddress)(params.contractAddress)) {
            throw new Error(`Invalid contract address: ${params.contractAddress}, expected a P2C address`);
        }
        if (params.methodIndex < 0) {
            throw new Error(`Invalid method index: ${params.methodIndex}`);
        }
        const allTokens = (params.tokens ?? []).concat([{ id: constants_1.ALPH_TOKEN_ID, amount: params.attoAlphAmount ?? 0n }]);
        const instrs = [
            ...genApproveAssets(this.callerLockupScript, this.approveTokens(allTokens)),
            ...genContractCall(params.contractAddress, params.methodIndex, params.args, params.retLength ?? 0)
        ];
        this.instrs.push(...instrs);
        return this;
    }
    getResult() {
        const method = {
            isPublic: true,
            usePreapprovedAssets: this.approvedAssets.size > 0,
            useContractAssets: false,
            usePayToContractOnly: false,
            argsLength: 0,
            localsLength: 0,
            returnLength: 0,
            instrs: this.instrs
        };
        const script = { methods: [method] };
        const bytecode = script_codec_1.scriptCodec.encode(script);
        const tokens = Array.from(this.approvedAssets.entries()).map(([id, amount]) => ({ id, amount }));
        this.approvedAssets.clear();
        this.instrs = [];
        return {
            signerAddress: this.callerAddress,
            signerKeyType: this.callerLockupScript.kind === 'P2PKH' ? 'default' : 'bip340-schnorr',
            bytecode: (0, utils_1.binToHex)(bytecode),
            attoAlphAmount: tokens.find((t) => t.id === constants_1.ALPH_TOKEN_ID)?.amount,
            tokens: tokens.filter((t) => t.id !== constants_1.ALPH_TOKEN_ID)
        };
    }
    addTokenToMap(tokenId, amount, map) {
        const current = map.get(tokenId);
        if (current !== undefined) {
            map.set(tokenId, current + amount);
        }
        else if (amount > 0n) {
            map.set(tokenId, amount);
        }
    }
    approveTokens(tokens) {
        const tokenAmounts = new Map();
        tokens.forEach((token) => {
            if (!((0, utils_1.isHexString)(token.id) && token.id.length === 64)) {
                throw new Error(`Invalid token id: ${token.id}`);
            }
            if (token.amount < 0n) {
                throw new Error(`Invalid token amount: ${token.amount}`);
            }
            this.addTokenToMap(token.id, token.amount, tokenAmounts);
            this.addTokenToMap(token.id, token.amount, this.approvedAssets);
        });
        return Array.from(tokenAmounts.entries()).map(([id, amount]) => ({ id, amount }));
    }
}
exports.DappTransactionBuilder = DappTransactionBuilder;
function genApproveAssets(callerLockupScript, tokens) {
    if (tokens.length === 0) {
        return [];
    }
    const approveInstrs = tokens.flatMap((token) => {
        if (token.id === constants_1.ALPH_TOKEN_ID) {
            return [(0, codec_1.U256Const)(token.amount), codec_1.ApproveAlph];
        }
        else {
            const tokenId = (0, codec_1.BytesConst)((0, utils_1.hexToBinUnsafe)(token.id));
            return [tokenId, (0, codec_1.U256Const)(token.amount), codec_1.ApproveToken];
        }
    });
    return [
        (0, codec_1.AddressConst)(callerLockupScript),
        ...Array.from(Array(tokens.length - 1).keys()).map(() => codec_1.Dup),
        ...approveInstrs
    ];
}
function bigintToNumeric(value) {
    return value >= 0 ? (0, codec_1.toU256)(value) : (0, codec_1.toI256)(value);
}
function strToNumeric(str) {
    const regex = /^-?\d+[ui]?$/;
    if (regex.test(str)) {
        if (str.endsWith('i'))
            return (0, codec_1.toI256)(BigInt(str.slice(0, str.length - 1)));
        if (str.endsWith('u'))
            return (0, codec_1.toU256)(BigInt(str.slice(0, str.length - 1)));
        return bigintToNumeric(BigInt(str));
    }
    throw new Error(`Invalid number: ${str}`);
}
function toAddressOpt(str) {
    if (!(0, utils_1.isBase58)(str))
        return undefined;
    try {
        return lockup_script_codec_1.lockupScriptCodec.decode((0, utils_1.base58ToBytes)(str));
    }
    catch (_) {
        return undefined;
    }
}
function genArgs(args) {
    return args.flatMap((arg) => {
        if (typeof arg === 'boolean')
            return arg ? [codec_1.ConstTrue] : [codec_1.ConstFalse];
        if (typeof arg === 'bigint')
            return bigintToNumeric(arg);
        if (typeof arg === 'string') {
            if ((0, utils_1.isHexString)(arg))
                return [(0, codec_1.BytesConst)((0, utils_1.hexToBinUnsafe)(arg))];
            const addressOpt = toAddressOpt(arg);
            if (addressOpt !== undefined)
                return (0, codec_1.AddressConst)(addressOpt);
            return strToNumeric(arg);
        }
        if (Array.isArray(arg))
            return genArgs(arg);
        if (arg instanceof Map)
            throw new Error(`Map cannot be used as a function argument`);
        if (typeof arg === 'object')
            return genArgs(Object.values(arg));
        throw new Error(`Unknown argument type: ${typeof arg}, arg: ${arg}`);
    });
}
exports.genArgs = genArgs;
function genContractCall(contractAddress, methodIndex, args, retLength) {
    const argInstrs = genArgs(args);
    return [
        ...argInstrs,
        (0, codec_1.toU256)(BigInt(argInstrs.length)),
        (0, codec_1.toU256)(BigInt(retLength)),
        (0, codec_1.BytesConst)((0, address_1.contractIdFromAddress)(contractAddress)),
        (0, codec_1.CallExternal)(methodIndex),
        ...Array.from(Array(retLength).keys()).map(() => codec_1.Pop)
    ];
}
