import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom"; // Removed BrowserRouter
import { Helmet } from "react-helmet";
import AOS from "aos";
import Layout from "./components/layout/Layout"; // Adjust import path as needed
import AppProvider from "./context/AppContext"; // Adjust import path as needed
import "aos/dist/aos.css";
import HomePage from "./pages/home/HomePage";
import TradePage from "./pages/trade/TradePage";
import LeaderboardPage from "./pages/leaderboard/LeaderboardPage";
import { web3 } from "@alephium/web3";
import './App.css'

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    AOS.init();
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>MyOnion.Fun - The showroom of memes and fun</title>
      </Helmet>

      <AppProvider>
        <Layout>
          {loading ? (
            <div className="preloader">
              <div className="preloader__inner">
                <div className="preloader__icon">
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/trade" element={<TradePage />} />
              <Route path="/leaderboard" element={<LeaderboardPage />} />
              <Route path="*" element={<h1>404 - Page Not Found</h1>} />
            </Routes>
          )}
        </Layout>
      </AppProvider>
    </>
  );
};

export default App;
