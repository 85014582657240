import React from "react";

const Banner = () => {
    return (
        <section className="banner banner--style2" id="home"
        >
            <div>
                <img src="images/bg/1.png" width={"100%"} style={{
                    width: "100%", /* Make the image scale responsively */
                    height: "auto", /* Maintain aspect ratio */
                    maxHeight: "800px", /* Ensure the image doesn't exceed container height */
                    objectFit: "cover" /* Ensure the image fills the container without distortion */
                }} />
            </div>
        </section>
    );
};

export default Banner;
