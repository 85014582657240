import React, { useEffect, useState } from "react";
import { Card, Row, Col, ProgressBar, Button, CardBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { BondingPairTypes } from "../../artifacts/ts";
import { hexToString } from "@alephium/web3";
import { useAppContext } from "../../context/AppContext";


interface TradePageStatsProps {
    token: any;
    tokenMeta: any;
    bondingPair: any;
    bondingState: BondingPairTypes.State;
    mcap: number;
}

const TradePageStats: React.FC<TradePageStatsProps> = ({
    token,
    tokenMeta,
    bondingPair,
    bondingState,
    mcap
}) => {
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/";

    const { alphPrice } = useAppContext();

    const [mcapCal, setMcapCalc] = useState<number>(1000);

    const calc = function () {
        const mcapCal = mcap * alphPrice;
        return {
            graduateAt: 20000 * alphPrice,
            kingOfHillAt: 10000 * alphPrice,
            graduatePercent: (mcapCal / 20000 * alphPrice) * 100,
            kingOfHillAtPercent: (mcapCal / (10000 * alphPrice)) * 100,
        }
    }

    return (
        <Card>
            <CardBody>
                <Row className="g-3 mb-3">
                    {/* Image Section */}
                    <Col xs={4}>
                        <Card.Img
                            src={`${logoBaseUrl}${hexToString(tokenMeta.logo)}`}
                            alt={hexToString(token.name)}
                            style={{ maxHeight: "80px", objectFit: "contain" }}
                        />
                    </Col>

                    {/* Content Section */}
                    <Col xs={8}>
                        <Card.Title as="h5" className="mb-1">
                            {hexToString(token.name)} <small>({hexToString(token.symbol)})</small>
                        </Card.Title>
                        <Card.Text className="truncate-2-lines mb-2" style={{ fontSize: "0.9rem" }}>
                            {hexToString(tokenMeta.description)}
                        </Card.Text>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Bonding Curve Progress */}
                        <div className="mb-3">
                            <small className="d-block mb-1">Bonding Curve Progress: {calc().graduatePercent.toFixed(0)}%</small>
                            <ProgressBar now={calc().graduatePercent} variant="success" style={{ height: "8px" }} />
                            <small style={{ fontSize: "0.8rem" }}>
                                Graduate this token to Onion DEX at ${calc().graduateAt.toFixed(0)} ALPH market cap.
                            </small>
                        </div>

                        {/* King of the Hill Progress */}
                        <div className="mb-3">
                            <small className="d-block mb-1">King of the Hill Progress: {calc().kingOfHillAtPercent.toFixed(0)}%</small>
                            <ProgressBar now={calc().kingOfHillAtPercent} variant="warning" style={{ height: "8px" }} />
                            <small style={{ fontSize: "0.8rem" }}>
                                Dethrone the current king at ${calc().kingOfHillAt.toFixed(0)} market cap.
                            </small>
                        </div>

                        {/* Social Links */}
                        <div className="d-flex align-items-center">
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="me-2"
                                href="https://twitter.com"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faTwitter} />
                            </Button>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="me-2"
                                href="https://t.me"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faTelegram} />
                            </Button>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                href="#"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faLink} /> ca: 2jdpK...
                            </Button>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default TradePageStats;
