import React, { useEffect, useRef } from 'react';
import { createChart, IChartApi, ISeriesApi, BarData, Time } from 'lightweight-charts';
import { ONE_ALPH, TransactionBuilder } from '@alephium/web3'
import { OnionRouter, OnionRouterInstance } from '../../artifacts/ts';
import { getContractConfig } from '../../services/utils';

const TradingViewChart = ({ tokenId }) => {
  const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`
  const onionRouter: OnionRouterInstance = OnionRouter.at(getContractConfig("OnionRouter").address);
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candlestickSeriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null);

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: 400,
        layout: {
          background: {
            color: "#0A0202"
          },
          textColor: '#FFFFFF',
        },
        grid: {
          vertLines: {
            color: '#333333',
          },
          horzLines: {
            color: '#333333',
          },
        },
        timeScale: {
          timeVisible: true, // Ensures time is displayed on the scale
          rightOffset: 12, // Adds space on the right
          barSpacing: 6,   // Adjusts spacing between bars
          fixLeftEdge: true, // Prevents dragging the chart to the left
          minBarSpacing: 2, // Ensures bars do not get too wide
        }
      });
      chartRef.current = chart;
      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#4CAF50',
        downColor: '#F44336',
        borderDownColor: '#F44336',
        borderUpColor: '#4CAF50',
        wickDownColor: '#F44336',
        wickUpColor: '#4CAF50',
        priceFormat: {
          type: 'price',
          precision: 8,
          minMove: 0.00000001,
        },
      });
      candlestickSeriesRef.current = candlestickSeries;
      chart.timeScale().fitContent();
      fetchDataAndUpdateChart();

      return () => {
        chart.remove();
      };
    }
  }, []);


  useEffect(() => {
    fetchDataAndUpdateChart()
    const interval = setInterval(fetchDataAndUpdateChart, 5000);
    return () => clearInterval(interval);
  }, []);

  const fetchDataAndUpdateChart = async () => {
    try {
      const response = await fetch(`${ANALYTICS_BASE_URL}/tradingview/chart/${onionRouter.address}?eventIndex=5&resolution=5M&from=${Date.now() - 3 * 2629800000}&to=${Date.now()}&tokenId=${tokenId}`);
      const data = await response.json();
      console.log(data);
      if (data.s === 'ok') {
        const bars: BarData[] = data.t.map((time: number, index: number) => ({
          time: time,
          low: data.l[index],
          high: data.h[index],
          open: data.o[index],
          close: data.c[index],
        }));
        candlestickSeriesRef.current?.setData(fillMissingCandles(bars, 300));
        if (chartRef && chartRef.current) chartRef.current.timeScale().fitContent();
      } else {
        console.error('No data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  function fillMissingCandles(data: BarData[], intervalInSeconds: number, lastClose?: number) {
    const filledData: BarData[] = [];

    if (data.length === 0) return filledData;

    // Adjust the first candle in the dataset if `lastClose` is provided
    if (lastClose !== undefined) {
      data[0] = {
        ...data[0],
        open: lastClose,
        high: Math.max(lastClose, data[0].high),
        low: Math.min(lastClose, data[0].low),
        close: data[0].close,
      };
    }

    for (let i = 0; i < data.length - 1; i++) {
      filledData.push(data[i]);

      const currentTime = Number(data[i].time);
      const nextTime = Number(data[i + 1].time);

      // Fill missing time slots
      let missingTime = currentTime + intervalInSeconds;
      while (missingTime < nextTime) {
        filledData.push({
          time: missingTime as Time,
          open: data[i].close,
          high: data[i].close,
          low: data[i].close,
          close: data[i].close,
        });
        missingTime += intervalInSeconds;
      }

      // Ensure the next actual data point opens at the last propagated close
      if (missingTime === nextTime) {
        data[i + 1] = {
          ...data[i + 1],
          open: data[i].close,
          high: Math.max(data[i].close, data[i + 1].high),
          low: Math.min(data[i].close, data[i + 1].low),
        };
      }
    }

    // Add the last data point
    filledData.push(data[data.length - 1]);

    return filledData;
  }




  return <div ref={chartContainerRef} style={{ width: '100%' }} />;
};

export default TradingViewChart;
