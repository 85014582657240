import React, { createContext, useContext, useEffect, useState } from "react";

import { AlephiumWalletProvider } from '@alephium/web3-react'
import { Transaction } from "@/components/utils/Transaction";
import { NetworkId, web3 } from "@alephium/web3";

interface AppContextType {
   transactions: Transaction[];
   setTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
   alphPrice: number;
}

export const ApadContext = createContext<AppContextType>({
   transactions: [],
   setTransactions: () => { },
   alphPrice: 0
});

export const useAppContext = () => useContext(ApadContext);

const AppProvider = ({ children }) => {
   const [transactions, setTransactions] = useState<Transaction[]>([]);
   const [alphPrice, setAlphPrice] = useState<number>(0);


   // Fetch market price data periodically
   useEffect(() => {
      const fetchPrice = async () => {
         try {
            const response = await fetch("https://backend.mainnet.alephium.org/market/prices?currency=usd", {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify(["ALPH"]),
            });
            const priceData = await response.json();
            setAlphPrice(priceData[0]);
         } catch (error) {
            console.error("Error fetching market price:", error);
         }
      };

      fetchPrice();

      // Update price every minute
      const interval = setInterval(fetchPrice, 60000);

      return () => clearInterval(interval); // Cleanup on unmount
   }, []);

   return (
      <AlephiumWalletProvider
         network={process.env.REACT_APP_NETWORK as NetworkId}
         addressGroup={parseInt(process.env.REACT_APP_ACCOUNT_GROUP ?? "0")}
         theme="simple-dark"
      >

         <ApadContext.Provider value={{ transactions, setTransactions, alphPrice }}>
            {children}
         </ApadContext.Provider>
      </AlephiumWalletProvider>
   );
};

export default AppProvider;