import { hexToString } from "@alephium/web3";
import { formatDistance, formatDistanceToNow } from "date-fns";

export function formatTxId(txId: string) {
    if (!txId || txId.length < 5) return <a>Invalid TxId</a>
    return (<a href={`${process.env.NEXT_PUBLIC_EXPLORER_URL}/transactions/${txId}`} target="_blank">{txId.slice(0, 3)}..{txId.slice(-3)}</a>)
}

export function formatAddress(address: string, name: string | undefined) {
    if (!address || address.length < 5) return <a>Invalid Address</a>
    return (<a className="white-text" href={`${process.env.NEXT_PUBLIC_EXPLORER_URL}/addresses/${address}`} target="_blank">{name ? name + ".alph" : (address.slice(0, 3) + ".." + address.slice(-3))}</a>)
}

export function formatImageSource(address: string, image: string) {
    return `/images/profiles/${generateNumberFromString(address)}.png`;
}


function generateNumberFromString(input: string): number {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash = (hash * 31 + input.charCodeAt(i)) >>> 0; // Simple hash function
    }
    return (hash % 9) + 1; // Map to range 1-9
}

const suffixMap: { [key: string]: string } = {
    'second': 'sec',
    'seconds': 'secs',
    'minute': 'min',
    'minutes': 'mins',
    'hour': 'hr',
    'hours': 'hrs',
    'day': 'day',
    'days': 'days',
    'week': 'wk',
    'weeks': 'wks',
    'month': 'mo',
    'months': 'mos',
    'year': 'yr',
    'years': 'yrs',
    'about': ''
};
const regex = new RegExp(Object.keys(suffixMap).join('|'), 'gi');

export function formatTimeAgo(timestamp: number) {
    const distance = formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    return distance.replace(regex, (matched) => suffixMap[matched.toLowerCase()]);
}


export function formatTimeAgoLong(timestamp: number) {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
}


export function parseSocials(socials: string) {
    const parts = hexToString(socials).split(";");
    const socialsObject: Record<string, string> = {};
    parts.forEach((part) => {
        const [key, value] = part.split(":");
        socialsObject[key] = value;
    });
    return {
        telegram: socialsObject["tg"] || "",
        discord: socialsObject["ds"] || "",
        twitter: socialsObject["tw"] || "",
    };
};