/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as DexPairContractJson } from "../trade/DexPair.ral.json";
import { getContractByCodeHash } from "./contracts";
import { TokenMetaData, UserProfile, AllStructs } from "./types";

// Custom types for the contract
export namespace DexPairTypes {
  export type Fields = {
    feeCollector: HexString;
    royaltyRecipient: Address;
    tokenId: HexString;
    reserve0: bigint;
    reserve1: bigint;
    blockTimeStampLast: bigint;
    price0CumulativeLast: bigint;
    price1CumulativeLast: bigint;
  };

  export type State = ContractState<Fields>;

  export type TradeEvent = ContractEvent<{
    caller: Address;
    alphAmount: bigint;
    tokenAmount: bigint;
    platformFeeAmount: bigint;
    royaltyFeeAmount: bigint;
  }>;

  export interface CallMethodTable {
    getFeeCollector: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    getRoyaltyRecipient: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getTokenPair: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<[HexString, HexString]>;
    };
    getReserves: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<[bigint, bigint]>;
    };
    getBlockTimeStampLast: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getPrice0CumulativeLast: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getPrice1CumulativeLast: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    update: {
      params: CallContractParams<{ newReserve0: bigint; newReserve1: bigint }>;
      result: CallContractResult<null>;
    };
    estimateOutput: {
      params: CallContractParams<{ amountIn: bigint; isBuy: boolean }>;
      result: CallContractResult<[bigint, bigint, bigint, bigint, bigint]>;
    };
    swap: {
      params: CallContractParams<{
        caller: Address;
        amountIn: bigint;
        isBuy: boolean;
      }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    uqdiv: {
      params: CallContractParams<{ a: bigint; b: bigint }>;
      result: CallContractResult<bigint>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    getFeeCollector: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getRoyaltyRecipient: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getTokenPair: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getReserves: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getBlockTimeStampLast: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getPrice0CumulativeLast: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getPrice1CumulativeLast: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    update: {
      params: SignExecuteContractMethodParams<{
        newReserve0: bigint;
        newReserve1: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
    estimateOutput: {
      params: SignExecuteContractMethodParams<{
        amountIn: bigint;
        isBuy: boolean;
      }>;
      result: SignExecuteScriptTxResult;
    };
    swap: {
      params: SignExecuteContractMethodParams<{
        caller: Address;
        amountIn: bigint;
        isBuy: boolean;
      }>;
      result: SignExecuteScriptTxResult;
    };
    uqdiv: {
      params: SignExecuteContractMethodParams<{ a: bigint; b: bigint }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<DexPairInstance, DexPairTypes.Fields> {
  encodeFields(fields: DexPairTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = { Trade: 0 };
  consts = {
    MINIMUM_AMOUNT: BigInt("10000"),
    ErrorCodes: {
      ReserveOverflow: BigInt("1"),
      InsufficientLiquidity: BigInt("5"),
      InvalidK: BigInt("6"),
      InsufficientOutputAmount: BigInt("7"),
      InvalidInputAmount: BigInt("8"),
      InputTooLarge: BigInt("9"),
    },
  };

  at(address: string): DexPairInstance {
    return new DexPairInstance(address);
  }

  tests = {
    getFeeCollector: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getFeeCollector", params, getContractByCodeHash);
    },
    getRoyaltyRecipient: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(
        this,
        "getRoyaltyRecipient",
        params,
        getContractByCodeHash
      );
    },
    getTokenPair: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<[HexString, HexString]>> => {
      return testMethod(this, "getTokenPair", params, getContractByCodeHash);
    },
    getReserves: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint]>> => {
      return testMethod(this, "getReserves", params, getContractByCodeHash);
    },
    getBlockTimeStampLast: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getBlockTimeStampLast",
        params,
        getContractByCodeHash
      );
    },
    getPrice0CumulativeLast: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getPrice0CumulativeLast",
        params,
        getContractByCodeHash
      );
    },
    getPrice1CumulativeLast: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getPrice1CumulativeLast",
        params,
        getContractByCodeHash
      );
    },
    update: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { newReserve0: bigint; newReserve1: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "update", params, getContractByCodeHash);
    },
    estimateOutput: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { amountIn: bigint; isBuy: boolean }
      >
    ): Promise<
      TestContractResultWithoutMaps<[bigint, bigint, bigint, bigint, bigint]>
    > => {
      return testMethod(this, "estimateOutput", params, getContractByCodeHash);
    },
    swap: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { caller: Address; amountIn: bigint; isBuy: boolean }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "swap", params, getContractByCodeHash);
    },
    uqdiv: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { a: bigint; b: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "uqdiv", params, getContractByCodeHash);
    },
  };

  stateForTest(
    initFields: DexPairTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const DexPair = new Factory(
  Contract.fromJson(
    DexPairContractJson,
    "",
    "5541b8e0dc25870cc9913747d74cc54ecef196008f8d68e24cb1c7e9c2c1672f",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class DexPairInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<DexPairTypes.State> {
    return fetchContractState(DexPair, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeTradeEvent(
    options: EventSubscribeOptions<DexPairTypes.TradeEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      DexPair.contract,
      this,
      options,
      "Trade",
      fromCount
    );
  }

  view = {
    getFeeCollector: async (
      params?: DexPairTypes.CallMethodParams<"getFeeCollector">
    ): Promise<DexPairTypes.CallMethodResult<"getFeeCollector">> => {
      return callMethod(
        DexPair,
        this,
        "getFeeCollector",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getRoyaltyRecipient: async (
      params?: DexPairTypes.CallMethodParams<"getRoyaltyRecipient">
    ): Promise<DexPairTypes.CallMethodResult<"getRoyaltyRecipient">> => {
      return callMethod(
        DexPair,
        this,
        "getRoyaltyRecipient",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getTokenPair: async (
      params?: DexPairTypes.CallMethodParams<"getTokenPair">
    ): Promise<DexPairTypes.CallMethodResult<"getTokenPair">> => {
      return callMethod(
        DexPair,
        this,
        "getTokenPair",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getReserves: async (
      params?: DexPairTypes.CallMethodParams<"getReserves">
    ): Promise<DexPairTypes.CallMethodResult<"getReserves">> => {
      return callMethod(
        DexPair,
        this,
        "getReserves",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getBlockTimeStampLast: async (
      params?: DexPairTypes.CallMethodParams<"getBlockTimeStampLast">
    ): Promise<DexPairTypes.CallMethodResult<"getBlockTimeStampLast">> => {
      return callMethod(
        DexPair,
        this,
        "getBlockTimeStampLast",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getPrice0CumulativeLast: async (
      params?: DexPairTypes.CallMethodParams<"getPrice0CumulativeLast">
    ): Promise<DexPairTypes.CallMethodResult<"getPrice0CumulativeLast">> => {
      return callMethod(
        DexPair,
        this,
        "getPrice0CumulativeLast",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getPrice1CumulativeLast: async (
      params?: DexPairTypes.CallMethodParams<"getPrice1CumulativeLast">
    ): Promise<DexPairTypes.CallMethodResult<"getPrice1CumulativeLast">> => {
      return callMethod(
        DexPair,
        this,
        "getPrice1CumulativeLast",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    update: async (
      params: DexPairTypes.CallMethodParams<"update">
    ): Promise<DexPairTypes.CallMethodResult<"update">> => {
      return callMethod(DexPair, this, "update", params, getContractByCodeHash);
    },
    estimateOutput: async (
      params: DexPairTypes.CallMethodParams<"estimateOutput">
    ): Promise<DexPairTypes.CallMethodResult<"estimateOutput">> => {
      return callMethod(
        DexPair,
        this,
        "estimateOutput",
        params,
        getContractByCodeHash
      );
    },
    swap: async (
      params: DexPairTypes.CallMethodParams<"swap">
    ): Promise<DexPairTypes.CallMethodResult<"swap">> => {
      return callMethod(DexPair, this, "swap", params, getContractByCodeHash);
    },
    uqdiv: async (
      params: DexPairTypes.CallMethodParams<"uqdiv">
    ): Promise<DexPairTypes.CallMethodResult<"uqdiv">> => {
      return callMethod(DexPair, this, "uqdiv", params, getContractByCodeHash);
    },
  };

  transact = {
    getFeeCollector: async (
      params: DexPairTypes.SignExecuteMethodParams<"getFeeCollector">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"getFeeCollector">> => {
      return signExecuteMethod(DexPair, this, "getFeeCollector", params);
    },
    getRoyaltyRecipient: async (
      params: DexPairTypes.SignExecuteMethodParams<"getRoyaltyRecipient">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"getRoyaltyRecipient">> => {
      return signExecuteMethod(DexPair, this, "getRoyaltyRecipient", params);
    },
    getTokenPair: async (
      params: DexPairTypes.SignExecuteMethodParams<"getTokenPair">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"getTokenPair">> => {
      return signExecuteMethod(DexPair, this, "getTokenPair", params);
    },
    getReserves: async (
      params: DexPairTypes.SignExecuteMethodParams<"getReserves">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"getReserves">> => {
      return signExecuteMethod(DexPair, this, "getReserves", params);
    },
    getBlockTimeStampLast: async (
      params: DexPairTypes.SignExecuteMethodParams<"getBlockTimeStampLast">
    ): Promise<
      DexPairTypes.SignExecuteMethodResult<"getBlockTimeStampLast">
    > => {
      return signExecuteMethod(DexPair, this, "getBlockTimeStampLast", params);
    },
    getPrice0CumulativeLast: async (
      params: DexPairTypes.SignExecuteMethodParams<"getPrice0CumulativeLast">
    ): Promise<
      DexPairTypes.SignExecuteMethodResult<"getPrice0CumulativeLast">
    > => {
      return signExecuteMethod(
        DexPair,
        this,
        "getPrice0CumulativeLast",
        params
      );
    },
    getPrice1CumulativeLast: async (
      params: DexPairTypes.SignExecuteMethodParams<"getPrice1CumulativeLast">
    ): Promise<
      DexPairTypes.SignExecuteMethodResult<"getPrice1CumulativeLast">
    > => {
      return signExecuteMethod(
        DexPair,
        this,
        "getPrice1CumulativeLast",
        params
      );
    },
    update: async (
      params: DexPairTypes.SignExecuteMethodParams<"update">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"update">> => {
      return signExecuteMethod(DexPair, this, "update", params);
    },
    estimateOutput: async (
      params: DexPairTypes.SignExecuteMethodParams<"estimateOutput">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"estimateOutput">> => {
      return signExecuteMethod(DexPair, this, "estimateOutput", params);
    },
    swap: async (
      params: DexPairTypes.SignExecuteMethodParams<"swap">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"swap">> => {
      return signExecuteMethod(DexPair, this, "swap", params);
    },
    uqdiv: async (
      params: DexPairTypes.SignExecuteMethodParams<"uqdiv">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"uqdiv">> => {
      return signExecuteMethod(DexPair, this, "uqdiv", params);
    },
  };

  async multicall<Calls extends DexPairTypes.MultiCallParams>(
    calls: Calls
  ): Promise<DexPairTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends DexPairTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<DexPairTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends DexPairTypes.MultiCallParams | DexPairTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(DexPair, this, callss, getContractByCodeHash);
  }
}
